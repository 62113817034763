

import { Loader } from '@beatport/ui/dist/cjs/Loader';
import React from 'react';

import './Loading.scss';

const Loading: React.FC = () => {
  return (
    <div className="Loading">
      <Loader size="large" loading />
    </div>
  )
}

export default Loading;