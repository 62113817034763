import { Integrations } from "@sentry/tracing";
import * as Sentry from '@sentry/react';

const fixSentryRelease = (ref) => {
  try {
    return ref.split('/').pop();
  } catch (e) {
    return ref;
  }
};

Sentry.init({ 
  dsn: SENTRY_DSN,
  release: fixSentryRelease(GITHUB_REF) || GITHUB_SHA,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 0.1,
  beforeSend: (event, hint) => {
    if (WEBPACK_ENV === 'production' && SENTRY_DSN) {
      return event;
    } else {
      console.error(hint.originalException || hint.syntheticException);
      return null;
    }
  }
});

export default Sentry;
