import React from 'react';

const IconArrowRight = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M189.964 124.465L74.536 9.036C71.386 5.886 66 8.117 66 12.571v230.857c0 4.455 5.386 6.687 8.536 3.536l115.428-115.428a4.984 4.984 0 0 0 1.465-3.536c0-1.28-.488-2.56-1.465-3.535z"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconArrowRight;
