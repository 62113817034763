import React, { Component } from 'react';

export class Tooltip extends Component {
    static defaultProps = {
        /**
         * @type {String} direction determines the direction and poistion of the
         * the tooltip
         */
        direction: 'down',
    }

    state = {
        /**
         * @type {Boolean} the current state determining whether the tooltip is
         * visible or not
         */
        hover: false,
    };

    onEnter = () => {
        this.setState({ hover: true });
    }

    onLeave = () => {
        this.setState({ hover: false });
    }

    render () {
        const { children, direction, tooltip, className, ...extra } = this.props;
        const containerClassName = `tooltip__container ${className || ''}`;
        let classList = `tooltip tooltip--${direction}`;
        classList = this.state.hover ? `${classList} tooltip--visible` : classList;

        return (
            <div onMouseOver={this.onEnter} onMouseOut={this.onLeave} {...extra} className={containerClassName}>
                {children}
                <div className={classList}>
                    <div className="tooltip__content">{tooltip}</div>
                </div>
            </div>
        );
    }
}
