import { makeBeatportUrl } from "@utils/urls";

export type ShareUrls = {
  facebookShareUrl?: string;
  twitterShareUrl?: string;
}

export default function makeShareUrls(track?: beatport.track) {
  if (!track) {
    return {}
  }
  
  let title = track.name;
  if (track.mix_name) {
    title += ` (${track.mix_name})`;
  }

  const shareTitle = `Check out ${title} on Beatport`

  const facebookShareUrl = `http://www.facebook.com/sharer/sharer.php?u=${makeBeatportUrl(track)}`;
  const twitterShareUrl = `http://twitter.com/intent/tweet?&text=${shareTitle}&url=${makeBeatportUrl(track)}`;

  return {
    facebookShareUrl,
    twitterShareUrl
  }
}
