import React, { Component } from 'react';
import { msToClock } from '~/components/utils';

export class Clock extends Component {
    constructor (props) {
        super(props);
        this.timeUpdateInterval = setInterval(this.updateClock, 250);
        this.state = {
            timestamp: 0,
        };
    }

    componentWillUnmount () {
        clearInterval(this.timeUpdateInterval);
    }

    updateClock = () => {
        const timestamp =
            typeof this.props.timestamp === 'function'
                ? this.props.timestamp()
                : this.props.timestamp;

        this.setState({ timestamp });
    };

    render () {
        return (
            <div className="Clock">
                <span className="Clock__played">
                    {msToClock(this.state.timestamp, true)}
                </span>
                <span className="Clock__total">
                    {msToClock(this.props.total, true)}
                </span>
            </div>
        );
    }
}

Clock.defaultProps = {
    timestamp: 0,
    length: 0,
};
