import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Track } from './Tracks';
import { TrackHeader } from './TrackHeader';
import { sorters } from '~/components/utils/sorters';

import TrackModel from '~/models/Track';

const sortTracks = (tracks, id, direction) => {
    if (!direction) {
        return tracks;
    }

    return direction === 1
        ? sorters[id](tracks)
        : sorters[id](tracks).reverse();
};

const reorderTracks = (onReorder, result, tracks) => {
    if (!result.destination) {
        return;
    }

    const currentIndex = result.source.index;
    const nextIndex = result.destination.index;

    const ordered = Array.from(tracks);
    const [removed] = ordered.splice(currentIndex, 1);
    ordered.splice(nextIndex, 0, removed);

    onReorder(ordered, currentIndex, nextIndex);
};

export const getDraggableStyle = (provided, snapshot) => snapshot.isDragging
    ? { ...provided.draggableProps.style, opacity: '.5' }
    : provided.draggableProps.style;

const Tracks = ({
    disabledIds,
    preorderIds,
    innerRef,
    renderTrack,
    tracks,
    zeroMessage,
    setActive,
    uniqueId,
    ...extra
}) => (
    <div className="tracks" ref={innerRef}>
        {tracks.map((track, index) =>
            renderTrack(track, index, (
                <div className="tracks__item" key={track[uniqueId]}>
                    <Track
                        track={track}
                        index={index}
                        position={index + 1}
                        preorder={preorderIds.includes(track[uniqueId])}
                        disabled={disabledIds.includes(track[uniqueId])}
                        active={setActive(track)}
                        {...extra}
                    />
                </div>
            ))
        )}
        {!tracks.length && zeroMessage && <div className="tracks__zero-message">{zeroMessage}</div>}
    </div>
);

Tracks.defaultProps = {
    renderTrack: (track, index, component) => component,
};

class TrackList extends Component {
    static defaultProps = {
        tracks: [],
        disabledIds: [],
        preorderIds: [],
        zeroMessage: '',
        onSort: () => { },
        setActive: () => false,
        uniqueId: 'id',
    }

    constructor (props) {
        super(props);

        this.state = {
            sort: [],
        };
    }

    setSort = (id, direction) => this.setState({ sort: { id, direction } })

    render () {
        const {
            onReorder,
            onSort,
            sort,
            tracks,
            uniqueId,
            ...extra
        } = this.props;

        const currentSort = sort === true ? this.state.sort : sort;
        const handleSort = sort === true ? this.setSort : onSort;

        const trackModels = tracks.map(track => {
            return track instanceof TrackModel
                ? track
                : new TrackModel(track);
        });

        const sortedTracks = currentSort && currentSort.override !== true
            ? sortTracks(trackModels, currentSort.id, currentSort.direction)
            : trackModels;

        if (onReorder) {
            return (
                <DragDropContext onDragEnd={result => reorderTracks(onReorder, result, sortedTracks)}>
                    <TrackHeader
                        {...extra}
                        sort={currentSort}
                        onSort={handleSort}
                    />
                    <Droppable droppableId="tracklist-droppable">
                        {(provided) => (
                            <div ref={provided.innerRef}>
                                <Tracks
                                    {...extra}
                                    tracks={sortedTracks}
                                    uniqueId={uniqueId}
                                    renderTrack={(track, index, component) => (
                                        <Draggable
                                            draggableId={track[uniqueId]}
                                            index={index}
                                            key={track[uniqueId]}
                                        >
                                            {(innerProvided, snapshot) => (
                                                <div
                                                    className="tracks__draggable"
                                                    ref={innerProvided.innerRef}
                                                    {...innerProvided.draggableProps}
                                                    {...innerProvided.dragHandleProps}
                                                    style={getDraggableStyle(innerProvided, snapshot)}
                                                >
                                                    {component}
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        }

        return (
            <Fragment>
                <TrackHeader
                    {...extra}
                    sort={currentSort}
                    onSort={handleSort}
                />
                <Tracks
                    {...extra}
                    tracks={sortedTracks}
                    uniqueId={uniqueId}
                />
            </Fragment>
        );
    }
}

export { TrackList };
