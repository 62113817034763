import React, { Fragment } from 'react';
import { Anchor } from '~/components/Anchors';
import { Tooltip } from '~/components/Tooltip';
import { urlFor, msToClock } from '~/components/utils';
import IconPlay from '~/components/Icons/svg/IconPlay.js';
import trackDefaultArtwork from '~/images/default.jpg';

import TrackModel from '~/models/Track';

const TrackArtwork = ({ track, artworkFn }) => {
    const url = artworkFn
        ? artworkFn(track)
        : track.artwork ? track.artwork : trackDefaultArtwork;

    return (
        <img
            className="track-artwork__image"
            src={url}
        />
    );
};

TrackArtwork.defaultProps = {
    artworkFn: null,
};

const TrackTitle = ({ className, style, track }) => {
    const classList = className ? `track-title ${className}` : 'track-title';
    return (
        <div className={classList} style={style}>
            <Anchor href={urlFor(track.type || 'tracks', track)}>
                <span className="track-title__primary">{track.name}</span>
                {track.mix_name
                    ? <span className="track-title__remixed">{track.mix_name}</span>
                    : ''}
            </Anchor>
        </div>
    );
};

const TrackArtists = ({ track }) => {
    return (
        <div className="track-artists">
            {track.artists.map((artist, index) => (
                <span key={index}>
                    {!!index && ', '}
                    <Anchor className="track-artists__artist" href={urlFor('artists', artist)}>
                        {artist.name}
                    </Anchor>
                </span>
            ))}
        </div>
    );
};

const TrackGenres = ({ track, genreFn, subGenreFn }) => {
    const genre = genreFn ? genreFn(track) : track.genre;
    const subGenre = subGenreFn ? subGenreFn(track) : track.sub_genre;

    return (
        <div className="track-genres">
            <Anchor className="track-genres__genre" href={urlFor('genres', genre)}>{genre.name}</Anchor>
            {subGenre && (
                <span>
                    {', '}
                    <Anchor className="track-genres__genre" href={urlFor('sub_genres', subGenre)}>
                        {subGenre.name}
                    </Anchor>
                </span>
            )}
        </div>
    );
};
TrackGenres.defaultProps = {
    genreFn: null,
    subGenreFn: null,
};

const TrackKey = ({ track }) => {
    return (
        <div className="track-key">{track.key}</div>
    );
};

const TrackBpm = ({ track }) => {
    return (
        <div className="track-bpm">{track.bpm ? `${track.bpm} BPM` : ''}</div>
    );
};

const TrackTime = ({ track }) => {
    let duration;
    try {
        duration = msToClock(track.length_ms);
    }
    catch (e) {
        duration = '';
    }

    return (
        <span className="track-time">{duration}</span>
    );
};

const TrackLabel = ({ track, labelFn }) => {
    const label = labelFn ? labelFn(track) : track.label;
    return (
        <div className="track-label">
            <Anchor className="track-label__label" href={urlFor('labels', label)}>
                {label.name}
            </Anchor>
        </div>
    );
};
TrackLabel.defaultProps = {
    labelFn: null,
};

const TrackReleased = ({ track }) => {
    return (
        <div className="track-released">{track.publish_date}</div>
    );
};

const TrackPurchased = ({ track }) => {
    const date = new Date(track.purchase_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

    return (
        <div className="track-released">{date}</div>
    );
};

const TrackFormat = ({ track }) => {
    const format = track?.audio_format?.name;

    if (!format) return '-';

    return (
        <div className="track-format">{format}</div>
    );
};

const TrackPosition = ({ position }) => {
    return (
        <div className="track-position">
            <span className="track-position__num">
                {position}
            </span>
        </div>
    );
};

const Track = ({
    active,
    track,
    position,
    highlight,
    showPosition,
    onPlayClick,
    showArtwork,
    artworkFn,
    showTitle,
    showGenre,
    genreFn,
    subGenreFn,
    showBpm,
    showLabel,
    labelFn,
    showPurchaseDate,
    showTrackFormat,
    renderActions,
    innerRef,
    small,
    disabled,
    disabledTooltip,
    preorder,
    preorderTooltip,
    style,
}) => {
    if (!(track instanceof TrackModel)) {
        track = new TrackModel(track); // eslint-disable-line no-param-reassign
    }

    let className = 'track';
    className += highlight ? ' track--highlight' : '';
    className += small ? ' track--small' : '';
    active && (className += ' track--active');
    disabled && (className += ' track--disabled');

    const showDisabled = disabled && disabledTooltip;
    const showPreorder = preorder && preorderTooltip;
    let tooltipText = null;

    if (showPreorder) {
        tooltipText = preorderTooltip;
    }
    if (showDisabled) {
        tooltipText = disabledTooltip;
    }

    const RootEl = showDisabled || showPreorder ? Tooltip : Fragment;
    const props = showDisabled || showPreorder ? { tooltip: tooltipText, className: 'track--full-width' } : {};

    return (
        <RootEl {...props}>
            <div className={className} ref={innerRef} style={style}>
                {showPosition && position && (
                    <div className="track__position" data-track={track.id}>
                        <TrackPosition position={position} />
                    </div>
                )}
                {showArtwork && (
                    <div className="track__artwork" data-track={track.id} onClick={() => onPlayClick(track)}>
                        <TrackArtwork track={track} size={65} artworkFn={artworkFn} />
                        <IconPlay className="track__play-icon" />
                    </div>
                )}
                {showTitle && (
                    <div className="track__col-1">
                        <TrackTitle track={track} />
                        <TrackArtists track={track} />
                    </div>
                )}
                {showGenre && (
                    <div className="track__col-2">
                        <TrackGenres
                            track={track}
                            genreFn={genreFn}
                            subGenreFn={subGenreFn}
                        />
                        <TrackTime track={track} />
                    </div>
                )}
                {showBpm && (
                    <div className="track__col-3">
                        <TrackKey track={track} />
                        <TrackBpm track={track} />
                    </div>
                )}
                {showLabel && (
                    <div className="track__col-4">
                        <TrackLabel track={track} labelFn={labelFn} />
                        <TrackReleased track={track} />
                    </div>
                )}
                {showPurchaseDate && (
                    <div className="track__col-5">
                        <TrackPurchased track={track} />
                        {showTrackFormat && (<TrackFormat track={track} />)}
                    </div>
                )}
                {typeof renderActions === 'function' && (
                    <div className="track__col-6">
                        {renderActions(track)}
                    </div>
                )}
            </div>
        </RootEl>
    );
};
Track.defaultProps = {
    position: null,
    active: false,
    highlight: false,
    disabled: false,
    preorder: false,
    showPosition: true,
    showArtwork: true,
    showTitle: true,
    showGenre: true,
    showBpm: true,
    showLabel: true,
    showPurchaseDate: false,
    showTrackFormat: false,
    artworkFn: null,
    labelFn: null,
    genreFn: null,
    subGenreFn: null,
    renderActions: null,
    innerRef: null,
    small: false,
    disabledTooltip: null,
};

export {
    TrackArtwork,
    TrackTitle,
    TrackArtists,
    TrackGenres,
    TrackKey,
    TrackBpm,
    TrackTime,
    TrackLabel,
    TrackReleased,
    TrackPosition,
    TrackPurchased,
    Track,
};
