import BaseAudioAdapter from './BaseAudioAdapter';
import { downSampleBuffer } from '~/components/utils';
import Track from '~/models/Track';
import Release from '~/models/Release';

/**
 * Audio adapter for playing full length sample files.
 */
export default class BasicPlaybackAdapter extends BaseAudioAdapter {
    constructor (mediaElement, audioContext, requestTrackPlaybackInfo) {
        super();
        this.mediaElement = mediaElement;
        this.audioContext = audioContext;
        this.requestTrackPlaybackInfo = requestTrackPlaybackInfo;
        this.bufferDownSampleRate = 4410;
        this._pitchShift = 0;

        this.reset();
    }

    reset () {
        this.pause();
        this.currentTime = 0;
        this.leftChannel = [];
        this.rightChannel = [];
        this.mediaElement.src = '';
        super.reset();
    }

    async load (track) {
        this.reset();
        this.track = track;

        // Handle when a track is played and no sample URL is found
        if (!this.track.sample_url) {
            const newPlaybackInfo = await this.requestTrackPlaybackInfo(track);
            let Model = Track;

            if (track.type === 'release') { Model = Release; }

            this.track = new Model({
                ...track._data,
                ...newPlaybackInfo
            });
        }

        this.getBufferFromUrl(this.track.sample_url)
            .then(buffer => {
                this.leftChannel = downSampleBuffer(
                    buffer.getChannelData(0),
                    this.audioContext.sampleRate,
                    this.bufferDownSampleRate
                );
                this.rightChannel = downSampleBuffer(
                    buffer.getChannelData(1),
                    this.audioContext.sampleRate,
                    this.bufferDownSampleRate,
                );
                return true;
            })
            .catch(console.error);

        return new Promise(resolve => {
            // one of these events will let us know the track can be started
            // ios sometimes does not fire loadeddata
            this.mediaElement.onloadeddata = resolve;
            this.mediaElement.oncanplay = resolve;
            this.mediaElement.onprogress = resolve;

            this.mediaElement.load();
            this.mediaElement.src = this.track.sample_url;
            this.pitchShift = this._pitchShift;
        });
    }

    addEventListener = (e, fn) => {
        this.mediaElement.addEventListener(e, fn);
    }

    play (time = null) {
        if (time) {
            this.currentTime = time;
        }

        return this.mediaElement.play();
    }

    pause () {
        try {
            this.mediaElement.pause();
        }
        catch (e) {
            // Do Nothing...
        }
    }

    set pitchShift (shift) {
        this._pitchShift = shift;
        this.mediaElement.playbackRate = shift + 1;
    }

    set currentTime (time) {
        this.mediaElement.currentTime = time;
    }

    get currentTime () {
        return this.mediaElement.currentTime;
    }

    set volume (volume) {
        this.mediaElement.volume = volume;
    }

    get volume () {
        return this.mediaElement.volume;
    }

    get duration () {
        return this.mediaElement.duration;
    }

    get data () {
        let track = this.track;
        return {
            leftChannel: this.leftChannel,
            rightChannel: this.rightChannel,
            trackLength: (track && track.length_ms) || (this.duration * 1000),
            sampleStart: (track && track.sample_start_ms) || 0,
            sampleEnd: (track && track.sample_end_ms) || (this.duration * 1000),
            bufferStart: 0,
            bufferEnd: (track && track.length_ms) || 0,
            sampleRate: this.bufferDownSampleRate,
        };
    }
}
