import React from 'react';
import { Loader } from '~/components/Loader/Loader';
import IconArrowUp from '~/components/Icons/svg/IconArrowUp.js';
import IconArrowDown from '~/components/Icons/svg/IconArrowDown.js';
import IconArrowLeft from '~/components/Icons/svg/IconArrowLeft.js';
import IconArrowRight from '~/components/Icons/svg/IconArrowRight.js';

const ButtonGroup = ({
    fullWidth,
    noBorderRadius,
    className,
    children,
    ...extra
}) => {
    const componentClass = 'button-group';
    let classList = componentClass;

    classList = fullWidth ? `${classList} ${componentClass}--full-width` : classList;
    classList = noBorderRadius ? `${classList} ${componentClass}--no-border-radius` : classList;
    classList = className ? `${classList} ${className}` : classList;

    return (
        <div className={classList} {...extra}>
            {children.map((item, index) => {
                let innerClassName = 'button-group__button';
                item?.props?.fullWidth ? innerClassName += ' button-group__button--full-width' : '';
                return <div key={index} className={innerClassName}>{item}</div>;
            })}
        </div>
    );
};

const Button = (props) => {
    let {
        children,
        withPadding,
        category,
        loading,
        disabled,
        nomin,
        noMargin,
        fullWidth,
        size,
        weight,
        className,
        type,
        ...extra
    } = props;
    let classList = 'button';

    if (loading) {
        disabled = true;
    }

    let categoryClass;
    category === 'primary' ? categoryClass = ' button--primary' : '';
    category === 'secondary' ? categoryClass = ' button--secondary' : '';
    category === 'hype' ? categoryClass = ' button--hype' : '';
    category === 'option' ? categoryClass = ' button--option' : '';
    category === 'cart' ? categoryClass = ' button--cart' : '';
    category === 'warning' ? categoryClass = ' button--warning' : '';
    category === 'outlined' ? categoryClass = ' button--outlined' : '';
    category === 'text' ? categoryClass = ' button--text' : '';

    categoryClass ? classList += ` ${categoryClass}` : '';
    categoryClass && weight === 'darken' ? classList += ` ${categoryClass}--darken` : '';
    categoryClass && weight === 'lighten' ? classList += ` ${categoryClass}--lighten` : '';

    size === 'medium' ? classList += ' button--medium' : '';
    size === 'large' ? classList += ' button--large' : '';
    size === 'small' ? classList += ' button--small' : '';

    loading ? classList += ' button--loading' : '';
    disabled ? classList += ' button--disabled' : '';
    nomin ? classList += ' button--nomin' : '';
    noMargin ? classList += ' button--no-margin' : '';
    fullWidth ? classList += ' button--full-width' : '';

    className ? classList += ` ${className}` : '';

    const contentClass = loading
        ? 'button__content button__content--loading'
        : 'button__content';

    return (
        <button className={classList} disabled={disabled} type={type} {...extra}>
            {loading && (
                <Loader size={size} loading />
            )}
            <div className={contentClass}>
                {withPadding
                    ? React.Children.map(children, child => (
                        <span className="button__content--with-padding">{child}</span>
                    ))
                    : children
                }
            </div>
        </button>
    );
};
Button.defaultProps = {
    loading: false,
    disabled: false,
    nomin: false,
    fullWidth: false,
    type: 'button',
};

const ArrowButton = ({ className, onClick, direction, enabled = true, ...extra }) => {
    let classList = 'arrow-button';
    let Icon;

    switch (direction) {
        case 'up':
            Icon = IconArrowUp;
            break;
        case 'right':
            Icon = IconArrowRight;
            break;
        case 'down':
            Icon = IconArrowDown;
            break;
        case 'left':
        default:
            Icon = IconArrowLeft;
            break;
    }

    if (!enabled) {
        classList += ' arrow-button--disabled';
    }

    if (className) {
        classList += className;
    }

    return (
        <Button
            className={classList}
            onClick={onClick}
            disabled={!enabled}
            {...extra}
        >
            <Icon />
        </Button>
    );
};

export { ButtonGroup, Button, ArrowButton };
