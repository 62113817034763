import React from 'react';

const IconPlus = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M216 117h-76V41a5 5 0 0 0-5-5h-13a5 5 0 0 0-5 5v76H41a5 5 0 0 0-5 5v13a5 5 0 0 0 5 5h76v76a5 5 0 0 0 5 5h13a5 5 0 0 0 5-5v-76h76a5 5 0 0 0 5-5v-13a5 5 0 0 0-5-5"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconPlus;
