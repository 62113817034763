const { uniqueId } = require('../components/utils');

const isValue = (v) => typeof v !== 'undefined';

class Model {
    constructor (data = {}, scheme = {}) {
        this._data = data;
        this.uiuid = uniqueId();

        for (let key in scheme) {
            !this.hasOwnProperty(key) && Object.defineProperty(this, key, {
                get: this._getterFactory(scheme[key]),
            });
        }

        for (let key in data) {
            !this.hasOwnProperty(key) && Object.defineProperty(this, key, {
                get: () => this._data[key],
            });
        }
    }

    get _ref () {
        return this._data;
    }

    _getNested (data, initialKey) {
        let result;
        const split = initialKey.split('.');
        try {
            if (isValue(data[split[0]])) {
                let val = data[split.shift()];

                while (split.length) {
                    const key = split.shift();
                    val && typeof val === 'object' && (val = val[key]);
                }

                result = val;
            }
        }
        catch (e) {
            console.error(`Failed to get nested key "${initialKey}" for data`, data, e);
        }

        return result;
    }

    _processMapping (map) {
        let value;

        if (typeof map === 'function') {
            try {
                value = map(this._data);
            }
            catch (e) {
                // Do Nothing...
            }
        }

        if (typeof map === 'string') {
            try {
                value = this._getNested(this._data, map);
            }
            catch (e) {
                // Do Nothing...
            }
        }

        return value;
    }

    _getterFactory (mapping) {
        const getter = () => {
            if (Array.isArray(mapping)) {
                for (let i in mapping) {
                    let map = mapping[i];
                    let value = this._processMapping(map);

                    if (isValue(value)) {
                        return value;
                    }
                }
            }

            if (
                typeof mapping === 'string' ||
        typeof mapping === 'function'
            ) {
                let value = this._processMapping(mapping);

                if (isValue(value)) {
                    return value;
                }
            }
        };

        return getter;
    }
}

module.exports = Model;
