import React from 'react';

const IconX = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M212.657 51.658l-9.193-9.193a5.001 5.001 0 0 0-7.07 0l-68.834 68.833-68.833-68.833a5 5 0 0 0-7.07 0l-9.193 9.193a5.001 5.001 0 0 0 0 7.07l68.833 68.834-68.833 68.832a5.001 5.001 0 0 0 0 7.07l9.193 9.194a4.998 4.998 0 0 0 7.07 0l68.833-68.833 68.833 68.833a5 5 0 0 0 7.071 0l9.193-9.193a5.001 5.001 0 0 0 0-7.071l-68.833-68.832 68.833-68.833a5.001 5.001 0 0 0 0-7.071"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconX;
