import * as React from 'react';

export const defaultAuthContext: AuthContext = {
  access_token: '',
  expires_in: 0,
  expires: '',
  token_type: '',
  scope: '',
};

export default React.createContext<AuthContext>(defaultAuthContext);
