import BaseAudioAdapter from './BaseAudioAdapter';
import Hls from 'hls.js';
import { downSampleBuffer } from '~/components/utils';

/**
 * Audio adapter for playing HLS streams using hls.js
 */
export default class HttpLiveStreamingAdapter extends BaseAudioAdapter {
    constructor (mediaElement, audioContext, requestNewStreamURL) {
        super();
        this.debug = false;

        this.mediaElement = mediaElement;
        this.audioContext = audioContext;
        this.requestNewStreamURL = requestNewStreamURL;
        this.bufferDownSampleRate = 4410;
        this.bufferStart = 0;
        this.bufferEnd = 0;
        this.currentSequenceNumber = 0;

        this._pitchShift = 0;

        this.hls = new Hls({
            // we have to disable the worker so we can get access to the
            // raw array buffer on FRAG_LOADED event. The array buffer is needed
            // so we can get the channel data for visulization.
            enableWorker: false,
        });

        this.reset();
    }

    registerEvents = () => {
        this.hls.on(Hls.Events.ERROR, (event, data) => {
            var errorDetails = data.details;

            if (errorDetails === Hls.ErrorDetails.FRAG_LOAD_ERROR) {
                this.requestNewStreamURL();
            }

            this.debug && console.error(event, data);
        });

        this.hls.on(Hls.Events.FRAG_CHANGED, this.hlsFragmentChanged);
        this.hls.on(Hls.Events.FRAG_LOADED, this.hlsFragmentLoaded);
    };

    unregisterEvents = () => {
        this.hls.off(Hls.Events.FRAG_CHANGED, this.hlsFragmentChanged);
        this.hls.off(Hls.Events.FRAG_LOADED, this.hlsFragmentLoaded);
    };

    reset () {
        this.pause();
        this.currentTime = 0;
        this.hls.detachMedia();
        this.hls.stopLoad();

        this.simpleManifest = {};
        this.leftChannel = [];
        this.rightChannel = [];

        this.unregisterEvents();
        super.reset();
    }

    load (track) {
        this.reset();
        this.track = track;

        return new Promise((resolve, reject) => {
            this.hls.once(Hls.Events.MANIFEST_PARSED, this.registerEvents);
            this.hls.once(Hls.Events.MANIFEST_PARSED, this.hlsManifestParsed);
            this.hls.once(Hls.Events.MANIFEST_PARSED, resolve);

            this.hls.once(Hls.Events.ERROR, reject);

            this.hls.loadSource(track.stream_url);
            this.hls.attachMedia(this.mediaElement);
            this.hls.startLoad();

            this.mediaElement.playbackRate = this._pitchShift + 1;
        });
    }

    sequenceHasBufferData = sn => {
        const dataCheck = () =>
            this.simpleManifest[sn] &&
            this.simpleManifest[sn].leftChannel &&
            this.simpleManifest[sn].leftChannel.length;

        return new Promise(resolve => {
            if (dataCheck()) {
                return resolve(true);
            }

            let i = setInterval(() => {
                if (dataCheck()) {
                    clearInterval(i);
                    resolve(true);
                }
            }, 100);
        });
    };

    hlsManifestParsed = (event, data) => {
        this.debug && console.info(event, data);
        const fragments = data.levels[0].details.fragments;
        this.simpleManifest = fragments.reduce((acc, cur) => {
            acc[cur.sn] = {
                filename: cur.relurl,
                length: cur.duration,
                start: cur.start,
                end: cur.start + cur.duration,
                leftChannel: null,
                rightChannel: null,
            };
            return acc;
        }, {});
    };

    hlsFragmentLoaded = (event, data) => {
        this.debug && console.info(event, data.frag.sn, data);

        const sn = data.frag.sn;
        const manifest = this.simpleManifest;

        this.decodeAudioDataPromise(data.networkDetails.response)
            .then(decodedData => {
                let leftChannel = downSampleBuffer(
                    decodedData.getChannelData(0),
                    this.audioContext.sampleRate,
                    this.bufferDownSampleRate
                );
                let rightChannel = downSampleBuffer(
                    decodedData.getChannelData(1),
                    this.audioContext.sampleRate,
                    this.bufferDownSampleRate
                );
                manifest[sn].leftChannel = leftChannel;
                manifest[sn].rightChannel = rightChannel;
                return [leftChannel, rightChannel];
            })
            .then(([leftChannel, rightChannel]) =>
                this.sequenceHasBufferData(this.currentSequenceNumber).then(() => [
                    leftChannel,
                    rightChannel,
                ])
            )
            .then(([leftChannel, rightChannel]) => {
                if (sn === this.currentSequenceNumber + 1) {
                    this.leftChannel = Array.from(this.leftChannel || []).concat(leftChannel);
                    this.rightChannel = Array.from(this.rightChannel || []).concat(rightChannel);
                    this.bufferEnd = manifest[sn].end;
                }

                if (sn === this.currentSequenceNumber - 1) {
                    this.leftChannel = leftChannel.concat(this.leftChannel);
                    this.rightChannel = rightChannel.concat(this.rightChannel);
                    this.bufferStart = manifest[sn].start;
                }
            })
            .catch(() => [[], []]);
    };

    hlsFragmentChanged = (event, data) => {
        this.debug && console.info(event, data);
        this.currentSequenceNumber = data.frag.sn;

        const sn = data.frag.sn;
        const manifest = this.simpleManifest;

        let leftChannel = Array.from(manifest[sn].leftChannel || []);
        let rightChannel = Array.from(manifest[sn].rightChannel || []);

        // current buffer
        let bufferStart = manifest[sn].start;
        let bufferEnd = manifest[sn].end;

        // previous buffer
        if (sn > 0 && manifest[sn - 1].leftChannel) {
            let prevLeft = Array.from(manifest[sn - 1].leftChannel || []);
            let prevRight = Array.from(manifest[sn - 1].rightChannel || []);

            leftChannel = prevLeft.concat(leftChannel);
            rightChannel = prevRight.concat(rightChannel);
            bufferStart = manifest[sn - 1].start;
        }

        // next buffer
        if (sn < Object.keys(manifest).length - 1 && manifest[sn + 1].leftChannel) {
            let nextLeft = Array.from(manifest[sn + 1].leftChannel || []);
            let nextRight = Array.from(manifest[sn + 1].rightChannel || []);

            leftChannel = leftChannel.concat(nextLeft);
            rightChannel = rightChannel.concat(nextRight);

            bufferEnd = manifest[sn + 1].end;
        }

        this.leftChannel = leftChannel;
        this.rightChannel = rightChannel;
        this.bufferStart = bufferStart;
        this.bufferEnd = bufferEnd;
    };

    addEventListener = (e, fn) => {
        this.mediaElement.addEventListener(e, fn);
    };

    play (time = null) {
        if (time) {
            this._currentTime = time;
        }

        return this.mediaElement.play();
    }

    pause () {
        try {
            this.mediaElement.pause();
        }
        catch (e) {
            // Do Nothing...
        }
    }

    set pitchShift (shift) {
        this._pitchShift = shift;
        this.mediaElement.playbackRate = shift + 1;
    }

    set currentTime (time) {
        this.mediaElement.currentTime = time;
    }

    get currentTime () {
        return this.mediaElement.currentTime;
    }

    set volume (volume) {
        this.mediaElement.volume = volume;
    }

    get volume () {
        return this.mediaElement.volume;
    }

    get duration () {
        return this.mediaElement.duration;
    }

    get data () {
        let track = this.track;
        return {
            sampleRate: this.bufferDownSampleRate,
            leftChannel: this.leftChannel,
            rightChannel: this.rightChannel,
            trackLength: (track && track.length_ms) || 0,
            sampleStart: (track && track.sample_start_ms) || 0,
            sampleEnd: (track && track.sample_end_ms) || 0,
            bufferStart: this.bufferStart * 1000,
            bufferEnd: this.bufferEnd * 1000,
        };
    }
}
