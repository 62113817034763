import EventEmitter from 'eventemitter3';
import Track from '~/models/Track';
import Release from '~/models/Release';

export const flatten = (arr, key = 'children') => {
    return arr.reduce((acc, cur) => {
        if (cur[key]) {
            let flat = flatten(cur[key], key);
            return acc.concat(flat);
        }
        else {
            acc.push(cur);
        }

        return acc;
    }, []);
};

export class Playlist extends EventEmitter {
    constructor (options = { maxLength: null, allowDuplicates: false }) {
        super();
        this._playlist = [];
        this._index = 0;
        this._maxLength = options.maxLength;
        this._allowDuplicates = options.allowDuplicates;
    }

    playlistUpdated () {
        this.emit('update', this);
    }

    trackChanged () {
        this.emit('trackchange', this.flattened[this.index]);
    }

    enforceMaxLength () {
        if (!this._maxLength) {
            return;
        }

        if ((this.length > this._maxLength)) {
            while (this.length > this._maxLength) {
                this._playlist.shift();
            }

            this.playlistUpdated();
        }
    }

    set current (item) {
        let ref = this.flattened.find(i => i._ref === item);
        let index = (ref && this.flattened.indexOf(ref));

        if (index > -1) {
            this.index = index;
            this.trackChanged();
        }
    }

    get current () {
        if (!this._playlist.length) {
            return null;
        }

        return this.flattened[this.index];
    }

    get flattened () {
        return flatten(this._playlist, 'tracks').filter(i => i);
    }

    set index (i) {
        this._index = i;
    }

    get index () {
        return this._index;
    }

    get length () {
        return this.flattened.length;
    }

    dump () {
        return this._playlist.reduce((acc, cur) => {
            if (cur instanceof Track) {
                acc.push(cur._ref);
            }

            if (cur instanceof Release) {
                let tracks = cur.tracks.map(t => t._ref);
                let release = cur._ref;
                release.tracks = tracks;
                acc.push(release);
            }

            return acc;
        }, []);
    }

    load (playlist) {
        playlist.forEach(item => {
            if (item.tracks) {
                this.addRelease(item, item.tracks);
                return;
            }

            this.addTrack(item);
        });
    }

    toArray () {
        return this._playlist;
    }

    clear () {
        this._playlist = [];
        this._index = 0;
        this.emit('clear', this);
    }

    at (offset) {
        return this.flattened[this.index + offset];
    }

    moveForward () {
        if (this.index < this.length - 1) {
            this.index += 1;
            this.trackChanged();
        }
    }

    moveBack () {
        if (this.index > 0) {
            this.index -= 1;
            this.trackChanged();
        }
    }

    addTracks (tracks) {
        let length = this.length;
        let trackArray = tracks.map(track => {
            if (!this._allowDuplicates && this.flattened.find(i => i._ref === track)) {
                return;
            }
            return new Track(track);
        }).filter(o => o);

        this._playlist.push(...trackArray);

        this.enforceMaxLength();
        length === 0 && this.trackChanged();
        this.playlistUpdated();
        return trackArray;
    }

    addTrack (track) {
        let length = this.length;
        let exists = !this._allowDuplicates && this.flattened.find(i => i._ref === track);
        if (exists) {
            return exists;
        }
        let t = new Track(track);
        this._playlist.push(t);

        this.enforceMaxLength();
        length === 0 && this.trackChanged();
        this.playlistUpdated();
        return t;
    }

    addRelease (release, tracks) {
        console.warn('DEPRECATION WARNING: This method has been deprecated in favor of treating all playables as tracks');
        let length = this.length;
        let exists = !this._allowDuplicates && this._playlist.find(i => i._ref === release);
        if (exists) {
            return exists;
        }

        release._tracks = tracks.map((t) => new Track(t));
        let r = new Release(release);
        this._playlist.push(r);

        this.enforceMaxLength();
        length === 0 && this.trackChanged();
        this.playlistUpdated();
        return r;
    }

    updateIndex (i) {
        if (i < this.index) {
            this.index -= 1;
        }

        if (this.index > this.length - 1) {
            this.index = this.length - 1;
        }
    }

    updateSpecificTrack (trackId, newInfo) {
        const foundIndex = this._playlist.findIndex(track => track.id === trackId);
        let Model = Track;

        if (this._playlist[foundIndex].type === 'release') { Model = Release; }

        this._playlist[foundIndex] = new Model({
            ...this._playlist[foundIndex]._data,
            ...newInfo
        });
    }

    removeTrack (track) {
        let trackUpdated = false;
        if (track === this.current._ref) {
            trackUpdated = true;
        }

        let ref = this._playlist.find(i => i._ref === track);
        let index = this._playlist.indexOf(ref);
        if (index > -1) {
            this._playlist.splice(index, 1);
            this.updateIndex(index);

            trackUpdated && this.trackChanged();
        }
        this.playlistUpdated();
    }

    removeRelease (release) {
        let trackUpdated = false;
        if (release._tracks.includes(this.current)) {
            trackUpdated = true;
        }

        let ref = this._playlist.find(i => i._ref === release);
        let index = this._playlist.indexOf(ref);
        if (index > -1) {
            this._playlist.splice(index, 1);
            this.updateIndex(index);

            trackUpdated && this.trackChanged();
        }
        this.playlistUpdated();
    }

    removeTrackFromRelease (release, track) {
        let trackUpdated = false;
        if (track === this.current) {
            trackUpdated = true;
        }

        let ref = this._playlist.find(i => i._ref === release);
        let index = this._playlist.indexOf(ref);
        if (index > -1) {
            let r = this._playlist[index];
            if (Array.isArray(r.tracks)) {
                let trackRef = this._playlist.find(i => i._ref === release);
                let trackIndex = this._playlist.indexOf(trackRef);

                if (trackIndex > -1) {
                    r.tracks.splice(trackIndex, 1);
                    this.updateIndex(trackIndex);
                }

                if (r.tracks.length === 0) {
                    this.removeRelease(r._ref);
                }

                trackUpdated && this.trackChanged();
            }
        }
        this.playlistUpdated();
    }
}
