import React, { Component } from 'react';

export class Fader extends Component {
    constructor (props) {
        super(props);
        this.startY = 0;
        this.startX = 0;
        this.startValue = 0;
    }

    onMouseMove = (e) => {
        let { min, max, step, invert, onChange, orientation } = this.props;

        let value;
        if (orientation === 'vertical') {
            value = invert
                ? this.startValue + ((e.clientY - this.startY) * step)
                : this.startValue - ((e.clientY - this.startY) * step);
        }
        else if (orientation === 'horizontal') {
            value = invert
                ? this.startValue - ((e.clientX - this.startX) * step)
                : this.startValue + ((e.clientX - this.startX) * step);
        }
        else {
            return;
        }

        if (value > max) {
            value = max;
        }

        if (value < min) {
            value = min;
        }

        this.setState({ value });
        onChange(value);
    };

    onMouseDown = (e) => {
        this.startValue = this.props.value;
        this.startY = e.clientY;
        this.startX = e.clientX;
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', this.onMouseMove);
        });
    };

    onDoubleClick = () => {
        let { shouldReset, resetTo, onChange } = this.props;

        shouldReset && onChange(resetTo);
    }

    render () {
        let {
            value,
            min,
            max,
            label,
            shouldReset, // eslint-disable-line no-unused-vars
            resetTo, // eslint-disable-line no-unused-vars
            invert,
            orientation,
            ...rest
        } = this.props;

        if (orientation !== 'vertical' && orientation !== 'horizontal') {
            console.warn(`Warning: orientation ${orientation} is not supported. Please use "vertical" or "horizontal"`);
        }

        let baseClass;
        if (orientation === 'vertical') {
            baseClass = 'FaderV';
        }

        if (orientation === 'horizontal') {
            baseClass = 'FaderH';
        }

        let style = {};
        let trackPosition = {};
        let offset = invert
            ? ((value - min) * 100) / (max - min)
            : ((max - value) * 100) / (max - min);

        // TODO - Implement trackPosition for other orientations
        if (orientation === 'vertical') {
            style = {
                top: `${Math.floor(offset)}%`,
            };
        }
        else if (orientation === 'horizontal') {
            style = {
                left: `${Math.abs(100 - Math.floor(offset))}%`,
            };

            if (!invert) {
                trackPosition = {
                    left: 0,
                    right: `${offset}%`,
                };
            }
        }

        return (
            <div className={baseClass}>
                <input
                    type="range"
                    orient="vertical"
                    value={value}
                    min={min}
                    max={max}
                    onChange={null}
                    {...rest}
                />
                <div className={`${baseClass}__wrapper`}>
                    <div className={`${baseClass}__track`}>
                        <div className={`${baseClass}__track-position`} style={trackPosition} />
                    </div>
                    <div className={`${baseClass}__knob-position`}>
                        <div
                            className={`${baseClass}__knob`}
                            style={style}
                            onMouseDown={this.onMouseDown}
                            onDoubleClick={this.onDoubleClick}
                        />
                    </div>
                </div>
                {label && (
                    <div className={`${baseClass}__label`}>
                        {label}
                    </div>
                )}
            </div>
        );
    }
}

Fader.defaultProps = {
    label: null,
    value: 0,
    min: 0,
    max: 127,
    step: 1,
    shouldReset: false,
    resetTo: null,
    onChange: () => null,
    invert: false,
    orientation: 'vertical',
};
