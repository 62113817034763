import React from 'react';

const IconPlay = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M64.839 33.893l131.193 90.478c2.882 1.987 2.882 6.245 0 8.232L64.839 223.08c-3.317 2.289-7.84-.086-7.84-4.116V38.009c0-4.03 4.523-6.404 7.84-4.116"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconPlay;
