import React from 'react';

const PlayerArtwork = ({ playlist, next, previous }) => {
    return (
        <div className="Player__artwork">
            {playlist.at(-2) && (
                <img
                    key={playlist.at(-2).uiuid}
                    id={playlist.at(-2).uiuid}
                    className="Player__artwork-0"
                    src={playlist.at(-2).artwork}
                />
            )}
            {playlist.at(-1) && (
                <img
                    key={playlist.at(-1).uiuid}
                    id={playlist.at(-1).uiuid}
                    className="Player__artwork-1"
                    src={playlist.at(-1).artwork}
                    onClick={previous}
                />
            )}
            {playlist.current && (
                <img
                    key={playlist.current.uiuid}
                    id={playlist.current.uiuid}
                    className="Player__artwork-2"
                    src={playlist.current.artwork}
                />
            )}
            {playlist.at(1) && (
                <img
                    key={playlist.at(1).uiuid}
                    id={playlist.at(1).uiuid}
                    className="Player__artwork-3"
                    src={playlist.at(1).artwork}
                    onClick={next}
                />
            )}
            {playlist.at(2) && (
                <img
                    key={playlist.at(2).uiuid}
                    id={playlist.at(2).uiuid}
                    className="Player__artwork-4"
                    src={playlist.at(2).artwork}
                />
            )}
        </div>
    );
};

export default PlayerArtwork;
