const Model = require('./Model');
const Track = require('./Track');

class Release extends Model {
    constructor (data) {
        const SCHEME = {
            artwork: [
                'images.large.url',
                'image.uri',
            ],
            tracks: [
                '_tracks',
                'tracks',
            ],
        };

        super(data, SCHEME);
    }

    get type () {
        return 'release';
    }

    addTrack (track) {
        if (this._ref._tracks.find(i => i.id === track.id)) {
            return;
        }

        if (Array.isArray(this._ref._tracks)) {
            this._ref._tracks.push(new Track(track));
            return;
        }

        this._ref._tracks = [new Track(track)];
    }
}

module.exports = Release;
