import React, { Component, Fragment } from 'react';
import MediaQuery from 'react-responsive';
import { FixedSizeList } from 'react-window';
import Hls from 'hls.js';
import Color from 'color';
import promiseFinally from 'promise.prototype.finally';
import IconPlay from '~/components/Icons/svg/IconPlay';
import IconPause from '~/components/Icons/svg/IconPause';
import IconPrev from '~/components/Icons/svg/IconPrev';
import IconNext from '~/components/Icons/svg/IconNext';
import IconPlus from '~/components/Icons/svg/IconPlus';
import IconMinus from '~/components/Icons/svg/IconMinus';
import IconChevronUp from '~/components/Icons/svg/IconChevronUp';
import IconX from '~/components/Icons/svg/IconX';
import IconVolumeOn from '~/components/Icons/svg/IconVolumeOn';
import IconVolumeOff from '~/components/Icons/svg/IconVolumeOff';
import { DrawerContainer } from '~/components/Drawer';
import { Waveform } from '~/components/Waveform';
import { Loader } from '~/components/Loader';
import { Playlist } from './Playlist';
import {
    Track,
    TrackTitle,
    TrackArtists,
    TrackKey,
    TrackBpm,
    TrackLabel,
} from '~/components/Tracks';
import { Clock } from './Clock';
import { Fader } from './Fader';
import PlayerErrorBoundary from './PlayerErrorBoundary';
import PlayerArtwork from './PlayerArtwork';
import BasicPlaybackAdapter from './audio-adapters/BasicPlaybackAdapter';
import HlsAdapter from './audio-adapters/HlsAdapter';
import StemsAdapter from './audio-adapters/StemsAdapter';
import { debouncePromise, makeCancelablePromise } from '~/components/utils';
import WaveformDefaultImage from '~/images/waveform-default.png';

promiseFinally.shim();

const STATUS = {
    PAUSED: 'PAUSED',
    PLAYING: 'PLAYING',
};

const COLOR_WHITE = Color('rgb(255, 255, 255)');
const COLOR_ACCENT = Color('rgb(57, 193, 222)');
const COLOR_GREY = Color('#888');

const ROW_HEIGHT = 44;

var AudioContext = window.AudioContext || window.webkitAudioContext;

const PlayerWaveform = React.memo(({
    playlist,
    toggleZoom,
    image,
    sampleStart,
    sampleEnd,
    trackLength,
    currentTime,
    onSeek,
    zoom,
    colorMap,
    mediaLoading,
    waveformSize,
    zoomEnabled,
}) => {
    let wrapperClassName = 'Player__waveform-wrapper';
    if (mediaLoading) {
        wrapperClassName += ' Player__waveform-wrapper--loading';
    }

    return (
        <div className="Player__waveform">
            <div className={wrapperClassName}>
                <Waveform
                    image={image}
                    sampleStart={sampleStart}
                    sampleEnd={sampleEnd}
                    trackLength={trackLength}
                    currentTime={currentTime}
                    onSeek={onSeek}
                    zoom={zoom}
                    colorMap={colorMap}
                    waveformSize={waveformSize}
                />
                {playlist.current && zoomEnabled && (
                    <div className="Player__waveform-controls">
                        {zoom
                            ? <IconMinus className="Player__waveform-zoom" onClick={toggleZoom} />
                            : <IconPlus className="Player__waveform-zoom" onClick={toggleZoom} />}
                    </div>
                )}
            </div>
        </div>
    );
});

const innerElementType = React.forwardRef(({ style, ...extra }, ref) => (
    <div
        ref={ref}
        style={{
            ...style,
            padding: '1rem 0',
        }}
        {...extra}
    />
));

export class Player extends Component {
    static getDerivedStateFromProps (props) {
        if (props.error) {
            return { error: props.error };
        }

        return null;
    }

    constructor (props) {
        super(props);
        props.debug && console.warn('Player constructed');
        props.debug && console.warn('Hls supported: ', Hls.isSupported());

        this.audioContext = new AudioContext();
        this.audioContext.onstagechange = () => {
            this.state.debug && console.warn('AudioContext state changed: ', this.audioContext.state);
        };

        this.initializeAudioAdapters();
        this._adapter = this.basicAdapter;

        this.currentTrack = React.createRef();
        this.waveform = React.createRef();
        this.waveformForeground = React.createRef();
        this.volumeRef = React.createRef();
        this.listRef = React.createRef();

        if (props.playlist) {
            this.playlist = props.playlist;
        }
        else {
            this.playlist = new Playlist({
                maxLength: props.playlistMaxLength,
                allowDuplicates: props.allowDuplicates,
            });
        }

        this.playlist.on('update', this.playlistUpdated);
        this.playlist.on('trackchange', this.loadTrack);

        document.addEventListener('touchstart', this.resumeAudioContext);
        document.addEventListener('touchend', this.resumeAudioContext);

        // hold the most recent call to `loadMedia` so it can be canceled if the user
        // changes the track while an load is being executed
        this.lastLoad = null;

        // debouncing the promise will stop unnecessary network requests for happening
        // if tracks are changed quickly
        this.loadMedia = debouncePromise(this.loadMedia, 0);

        this.state = {
            /**
             * @type {Boolean} shows debug output
             */
            debug: props.debug,
            /**
             * @type {Boolean} tracks playing / pause status
             */
            status: STATUS.PAUSED,
            /**
             * @type {null|Error} there was an error loading or playing a track
             */
            error: null,
            /**
             * @type {Number} current timestamp in millseconds to be passed down to Clock
             */
            timestamp: 0,
            /**
             * @type {Number} playback volume
             */
            volume: 0.75,
            /**
             * @type {Boolean} if the volume control is open
             */
            volumeOpen: false,
            /**
             * @type {Boolean} should the preview waveform be zoomed to show only the playable area
             */
            zoom: false,
            /**
             * @type {Boolean} Should playlist be open (only available if props.enablePlaylist is true)
             */
            playlistVisible: props.initPlaylistOpenState,
            /**
             * @type {Playlist} a reference to the current playlist. Set in the state so the playlist updater
             * callback will trigger a re-render of this component.
             */
            playlist: this.playlist,
            /**
             * @type {Boolean} should we try to use Hls
             */
            shouldUseHls: props.needleDropEnabled,
            /**
             * @type {Boolean} set to true when loadMedia has stared an async load and
             * flase when it has completed or errored.
             */
            mediaLoading: false,
        };
    }

    componentDidMount () {
        this.loadTrack();
    }

    componentDidUpdate (prevProps) {
        if (prevProps.needleDropEnabled !== this.props.needleDropEnabled) {
            this.setState({
                shouldUseHls: this.props.needleDropEnabled,
            });
        }
    }

    componentWillUnmount () {
        this.stop();
    }

    onPlayClick = async track => {
        this.setState({ status: STATUS.PLAYING });
        this.state.playlist.current = track._ref;
        this.props.onPlayStateChange(STATUS.PLAYING);
    };

    onSeek = timeMs => {
        if (typeof this.props.onSeek === 'function') {
            this.props.onSeek(timeMs);
            this.updateClock();
            return;
        }

        this.playback.currentTime = (timeMs - this.playback.data.sampleStart) / 1000;
        this.updateClock();
    };

    resumeAudioContext = () => {
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }
    }

    initializeAudioAdapters () {
        const requestTrackPlaybackInfo = async (track) => {
            const playbackInfo = await this.props.retrieveTrackPlaybackInfo(track);
            // Update playlist to save the new playback info.
            this.state.playlist.updateSpecificTrack(track.id, playbackInfo);
            // Return the new playback info directly to the PlaybackAdapter for immidiate use.
            return playbackInfo;
        };

        const requestNewStreamURL = () => {
            this.loadTrack({ currentPlayTime: this.playback.currentTime });
        };

        this.mediaElement = document.createElement('audio');

        /**
         * Note: preservesPitch is supposed to be implemented in some browsers,
         * but seems to only work in Firefox with the moz prefix.
         */
        this.mediaElement.preservesPitch = false;
        this.mediaElement.webkitPreservesPitch = false;
        this.mediaElement.mozPreservesPitch = false;

        this.basicAdapter = new BasicPlaybackAdapter(this.mediaElement, this.audioContext, requestTrackPlaybackInfo);
        this.hlsAdapter = new HlsAdapter(this.mediaElement, this.audioContext, requestNewStreamURL);
        this.stemsAdapter = new StemsAdapter(this.mediaElement, this.audioContext);

        this.mediaElement.onended = this.next;
    }

    get hlsSupported () {
        return Hls.isSupported();
    }

    get adapters () {
        return [
            this.basicAdapter,
            this.hlsAdapter,
            this.stemsAdapter,
        ];
    }

    get playback () {
        let { playlist } = this.state;
        let lastAdapter = this._adapter;

        if (playlist.current && playlist.current.type === 'stem') {
            this._adapter = this.stemsAdapter;
        }
        else if (this.needledropCheck()) {
            this._adapter = this.hlsAdapter;
        }
        else {
            this._adapter = this.basicAdapter;
        }

        if (this.props.debug && Object.getPrototypeOf(this._adapter) !== Object.getPrototypeOf(lastAdapter)) {
            if (this._adapter instanceof BasicPlaybackAdapter) {
                console.warn('Using BasicPlaybackAdapter');
            }
            if (this._adapter instanceof HlsAdapter) {
                console.warn('Using HlsAdapter');
            }
            if (this._adapter instanceof StemsAdapter) {
                console.warn('Using StemsAdapter');
            }
        }

        return this._adapter;
    }

    get currentTime () {
        return this.playback.currentTime * 1000;
    }

    get sampleStart () {
        const { playlist, shouldUseHls } = this.state;
        const trackStart = playlist.current && playlist.current.sample_start_ms;
        const playbackStart = this.playback.data.sampleStart;
        // some tracks may not have a sample start on the object, fallback to playback adapter.
        const sampleStart = trackStart || playbackStart;
        // If the player is using hls and fulltrack playback is enabled default sample start to 0
        const fulltrackPlayback = shouldUseHls && this.props.fulltrackEnabled;

        return fulltrackPlayback ? 0 : sampleStart;
    }

    get sampleEnd () {
        const { playlist, shouldUseHls } = this.state;
        const trackEnd = playlist.current && playlist.current.sample_end_ms;
        const playbackEnd = this.playback.data.sampleEnd;
        // some tracks may not have a sample start on the object, fallback to playback adapter.
        const sampleEnd = trackEnd || playbackEnd;
        // If the player is using hls and fulltrack playback is enabled default sample end to track length_ms
        const fulltrackPlayback = shouldUseHls && this.props.fulltrackEnabled;

        return fulltrackPlayback ? this.lengthMs : sampleEnd;
    }

    get lengthMs () {
        let { playlist } = this.state;
        let trackLength = playlist.current && playlist.current.length_ms;
        let playbackLength = this.playback.data.sampleEnd;
        // some tracks may not have a sample start on the object, fallback to playback adapter.
        return trackLength || playbackLength;
    }

    set currentTime (timeMs) {
        this.playback.currentTime = timeMs / 1000;
        this.updateClock();
    }

    clearPlaylist = () => {
        this.state.playlist.clear();
        this.stop();
        this.playbackReset();
    };

    updateClock = () => {
        this.playerTrackTime && this.playerTrackTime.updateClock();
    };

    playlistUpdated = playlist => {
        if (!playlist.length) {
            this.stop();
        }
        this.setState({ playlist });
    };

    scrollToCurrentTrack () {
        if (!this.props.autoScrollTrack) return;
        // react-window specific scroll to item function
        this.listRef.current?.scrollToItem(this.state.playlist.index);
    }

    previous = async () => {
        if (this.state.playlist.at(-1)) {
            this.state.playlist.moveBack();
        }
        else {
            this.playback.currentTime = 0;
        }
        this.scrollToCurrentTrack();
    };

    next = async () => {
        if (this.state.playlist.at(1)) {
            this.state.playlist.moveForward();
            this.scrollToCurrentTrack();
        }
        else {
            this.setState({ status: STATUS.PAUSED });
            this.props.onPlayStateChange(STATUS.PAUSED);
        }
    };

    playbackPause () {
        this.adapters.forEach(a => a.pause());
    }

    playbackReset () {
        this.adapters.forEach(a => a.reset());
    }

    playPause = () => {
        this.state.status === STATUS.PLAYING ? this.stop() : this.play();
    };

    async streamUrl (track) {
        this.state.debug && console.warn('streamUrl');

        if (this.props.streamUrlOverride) {
            this.state.debug && console.warn('streamUrl override');
            return this.props.streamUrlOverride(track);
        }

        return new Promise((resolve) => {
            resolve(
                track &&
                track.stream_url
            );
        });
    }

    loadTrack = async ({ currentPlayTime = 0 } = {}) => {
        this.playbackPause();
        this.playback.currentTime = 0;

        if (this.lastLoad) {
            this.lastLoad.cancel();
        }

        if (this.resolveLastStreamEarly) {
            this.resolveLastStreamEarly();
        }

        if (this.playlist.at(0)) {
            this.state.debug && console.warn('load track');
            this.lastLoad = makeCancelablePromise(this.load());
            return this.lastLoad.promise
                .then(this.playback.ready)
                .then(() => {
                    if (this.props.deferLoadMedia) {
                        return;
                    }

                    return this.loadMedia();
                })
                .then(() => {
                    if (this.state.status === STATUS.PLAYING) {
                        this.play(currentPlayTime);
                        this.scrollToCurrentTrack();
                    }
                })
                .catch(e => {
                    if (e.isCanceled) return;
                    this.props.onError(e);
                });
        }
    }

    load = async (
        useHls = Boolean(
            this.hlsSupported &&
            this.props.needleDropEnabled &&
            this.playlist.current &&
            this.props.validateStreaming(this.playlist.current)
        )
    ) => {
        this.state.debug && console.warn('load');
        this.setState({
            error: null,
            mediaLoading: true,
            shouldUseHls: useHls,
        });

        // the side effect of this is that the playback adapter is
        // swapped out behind the scenes, so setting the playback track comes after
        return this.streamUrl(this.playlist.current)
            .catch(() => {
                this.setState({
                    shouldUseHls: !!(
                        // Preorder tracks are not allowed to be streamed
                        !this.playlist.current?.preorder &&
                        this.playlist.current?.is_available_for_streaming &&
                        this.props.needleDropEnabled
                    ),
                });
            })
            .finally(() => {
                this.playback.track = this.playlist.current;
                this.updateClock();
            });
    };

    loadMedia = async () => {
        this.state.debug && console.warn('load media');
        this.playbackPause();
        this.playbackReset();
        // hls.js needs a fresh audio element or segments bleed through
        this.playback instanceof HlsAdapter && this.initializeAudioAdapters();
        this.playback.volume = this.state.volume;

        try {
            await this.playback.load(this.state.playlist.current);
            this.setState({ mediaLoading: false });
        }
        catch (error) {
            this.state.debug && console.warn('loadMedia failed to load playback:', error);
            this.setState({ error, mediaLoading: false });
            if (this.state.shouldUseHls) {
                this.state.debug && console.warn('attempting to fall back to 2 minute preview...');
                await this.load(false).then(this.loadMedia);
            }
        }
    };

    play = async (time = this.playback.currentTime) => {
        if (this.props.deferLoadMedia) {
            await this.loadMedia();
        }

        this.state.debug && console.warn('play');
        if (!this.playlist.current) return;

        await this.playback.play(time);
        this.setState({ status: STATUS.PLAYING });
        this.props.onPlayStateChange(STATUS.PLAYING);
        this.props.onPlayChange(this.playlist.current);

        this.updateClock();
        this.scrollToCurrentTrack();
    };

    stop = () => {
        this.playbackPause();
        this.setState({ status: STATUS.PAUSED });
        this.props.onPlayStateChange(STATUS.PAUSED);
    };

    needledropCheck = () => {
        return (
            this.hlsSupported &&
            this.props.needleDropEnabled &&
            this.state.shouldUseHls &&
            this.state.playlist.current &&
            this.props.validateStreaming(this.playlist.current)
        );
    };

    setVolume = volume => {
        this.playback.volume = volume;
        this.setState({
            volume: volume,
        });
    }

    toggleVolumeControl = (open) => {
        if (open) {
            const volumeOpenOff = () => {
                !this.state.volumeOpen && this.setState({ volumeOpen: false });
                document.removeEventListener('click', volumeOpenOff);
            };

            document.addEventListener('click', volumeOpenOff);
        }

        this.setState({ volumeOpen: open });
    }

    toggleZoom = () => {
        this.setState({
            zoom: !this.state.zoom,
        });
    };

    togglePlaylist = () => {
        this.setState({
            playlistVisible: !this.state.playlistVisible,
        });
    };

    renderTrackActions = (track, release = null) => {
        if (typeof this.props.renderTrackActions === 'function') {
            return this.props.renderTrackActions(track, release);
        }

        return (
            <IconX
                className="Player__playlist-remove-track"
                onClick={() => {
                    if (release) {
                        this.state.playlist.removeTrackFromRelease(release._ref, track._ref);
                        return;
                    }
                    this.state.playlist.removeTrack(track._ref);
                }}
            />
        );
    }

    renderTrack (track, style, index) {
        const currentTrack = track === this.state.playlist.current && this.state.playlist.index === index;
        return (
            <PlayerErrorBoundary onError={this.props.onError} key={track.uiuid}>
                <Track
                    className={`${track.active ? 'track--active' : ''}`}
                    key={track.uiuid}
                    track={track}
                    highlight={currentTrack}
                    innerRef={currentTrack ? this.currentTrack : null}
                    style={style}
                    onPlayClick={this.onPlayClick}
                    renderActions={this.renderTrackActions}
                    {...this.props.trackProps}
                />
            </PlayerErrorBoundary>
        );
    }

    vhToPx = value => {
        const val = value.substr(0, value.length - 2);
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const y = w.innerHeight || e.clientHeight || g.clientHeight;
        const result = (y * Number(val)) / 100;
        return result;
    }

    renderPlaylist = playlist => {
        return (
            <FixedSizeList
                height={this.vhToPx(this.props.playlistMaxHeight)}
                itemData={playlist}
                itemCount={playlist.length}
                itemSize={ROW_HEIGHT}
                innerElementType={innerElementType}
                width="100%"
                ref={this.listRef}
            >
                {({ index, style, data }) => {
                    const item = data[index];
                    return this.renderTrack(item, style, index);
                }}
            </FixedSizeList>
        );
    }

    render () {
        const {
            playlist,
            status,
            zoom,
            playlistVisible,
            volume,
            volumeOpen,
            shouldUseHls,
            error,
            mediaLoading,
        } = this.state;

        const {
            fulltrackEnabled,
            PlayerArtwork, // eslint-disable-line no-shadow
            playlistEnabled,
            playlistMaxHeight,
            renderPlayerExtra,
            volumeFaderOrientation,
            waveformColorMap,
            waveformSize,
            zoomEnabled,
        } = this.props;

        const classList = ['Player'];

        if (playlist.length === 0) classList.push('Player--playlist-empty');
        if (volumeOpen) classList.push('Player--volume-open');

        const bpm = (this.state.playlist.current && this.state.playlist.current.bpm) || 0;
        const bpmDisplay = Number(bpm).toFixed(2);
        const VolumeIcon = volume ? IconVolumeOn : IconVolumeOff;
        const colorMap = waveformColorMap || (shouldUseHls
            ? {
                COLOR_TRACK_PLAYED: COLOR_ACCENT.lighten(0.4),
                COLOR_TRACK_UNPLAYED: COLOR_ACCENT,
                COLOR_TRACK_AVAILABLE: COLOR_ACCENT.darken(0.4),
                COLOR_MARKER: COLOR_WHITE,
            }
            : {
                COLOR_TRACK_PLAYED: COLOR_ACCENT.lighten(0),
                COLOR_TRACK_UNPLAYED: COLOR_ACCENT.darken(0.4),
                COLOR_TRACK_AVAILABLE: COLOR_GREY,
                COLOR_MARKER: COLOR_WHITE,
            });

        const playlistToggleClasslist = ['Player__playlist-toggle'];
        if (!playlist.length) {
            playlistToggleClasslist.push('Player__playlist-toggle--disabled');
        }
        else if (playlist.length && playlistVisible) {
            playlistToggleClasslist.push('Player__playlist-toggle--open');
        }

        const fulltrackPlayback = shouldUseHls && fulltrackEnabled;
        const renderWaveform = () => {
            return (
                <PlayerWaveform
                    playlist={this.playlist}
                    toggleZoom={this.toggleZoom}
                    image={playlist.current && (playlist.current.waveform_image || WaveformDefaultImage)}
                    sampleStart={() => this.sampleStart}
                    sampleEnd={() => this.sampleEnd}
                    trackLength={() => this.lengthMs}
                    currentTime={() => this.sampleStart + this.currentTime}
                    onSeek={this.onSeek}
                    zoom={zoom}
                    error={error}
                    colorMap={colorMap}
                    mediaLoading={mediaLoading}
                    waveformSize={waveformSize}
                    zoomEnabled={!fulltrackPlayback && zoomEnabled}
                />
            );
        };

        const renderPlaylist = () => playlistEnabled && this.state.playlistVisible && Boolean(playlist.length) && (
            <PlayerErrorBoundary onError={this.props.onError}>
                <DrawerContainer
                    position="bottom"
                    open={playlistVisible}
                    size="auto"
                    drawer={() => (
                        <Fragment>
                            <div
                                className="Player__playlist-clear"
                                onClick={this.clearPlaylist}
                            >
                                Clear Playlist
                            </div>
                            <div className="Player__playlist" style={{ maxHeight: playlistMaxHeight }}>
                                {this.renderPlaylist(playlist.flattened)}
                            </div>
                        </Fragment>
                    )}
                />
            </PlayerErrorBoundary>
        );

        return (
            <PlayerErrorBoundary onError={this.props.onError}>
                <div className={classList.join(' ')}>
                    <MediaQuery maxWidth={1024}>
                        {renderPlaylist()}
                    </MediaQuery>
                    <div className="Player__container">
                        <PlayerErrorBoundary onError={this.props.onError}>
                            <PlayerArtwork playlist={playlist} next={this.next} previous={this.previous} />
                        </PlayerErrorBoundary>
                        <PlayerErrorBoundary onError={this.props.onError}>
                            <div className="Player__track-info">
                                {playlist.current && (
                                    <Fragment>
                                        <TrackTitle track={playlist.current} />
                                        <TrackArtists track={playlist.current} />
                                        <TrackLabel track={playlist.current} />
                                    </Fragment>
                                )}
                            </div>
                        </PlayerErrorBoundary>
                        <PlayerErrorBoundary onError={this.props.onError}>
                            <div className="Player__track-info-2">
                                {playlist.current && (
                                    <div className="Player__track-info-2-inner">
                                        <Clock
                                            ref={r => {
                                                this.playerTrackTime = r;
                                            }}
                                            timestamp={() => this.sampleStart + this.currentTime}
                                            total={this.lengthMs}
                                        />
                                        <div className="Player__track-bpm">
                                            {!!bpm && <TrackBpm track={{ bpm: bpmDisplay }} />}
                                        </div>
                                        <div className="Player__track-key">
                                            <TrackKey track={playlist.current} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </PlayerErrorBoundary>
                        <MediaQuery minWidth={1025}>
                            <PlayerErrorBoundary onError={this.props.onError}>
                                {renderWaveform()}
                            </PlayerErrorBoundary>
                        </MediaQuery>
                        {typeof renderPlayerExtra === 'function' && (
                            <PlayerErrorBoundary onError={this.props.onError}>
                                <div className="Player__extra">
                                    {renderPlayerExtra(this.state)}
                                </div>
                            </PlayerErrorBoundary>
                        )}
                        <MediaQuery minWidth={1025}>
                            <PlayerErrorBoundary onError={this.props.onError}>
                                <div className={`Player__volume${volumeOpen ? ' Player__volume--open' : ''}`} ref={this.volumeRef}>
                                    {volumeOpen && (
                                        <div className="Player__volume-fader">
                                            <Fader
                                                value={volume}
                                                min={0}
                                                max={1}
                                                step={0.01}
                                                shouldReset={true}
                                                resetTo={0.75}
                                                onChange={this.setVolume}
                                                orientation={volumeFaderOrientation}
                                            />
                                        </div>
                                    )}
                                    <VolumeIcon
                                        onClick={() => this.toggleVolumeControl(!this.state.volumeOpen)}
                                    />
                                </div>
                            </PlayerErrorBoundary>
                        </MediaQuery>
                        <div className="Player__controls">
                            <div
                                className="Player__button Player__button--small"
                                onClick={this.previous}
                            >
                                <IconPrev />
                            </div>
                            {status === STATUS.PAUSED && (
                                <div
                                    id="Player__play-button"
                                    className="Player__button"
                                    onClick={() => !this.state.mediaLoading && this.play(this.playback.currentTime)}
                                >
                                    {
                                        this.state.mediaLoading
                                            ? this.props.renderLoader()
                                            : <IconPlay />
                                    }
                                </div>
                            )}
                            {status === STATUS.PLAYING && (
                                <div
                                    id="Player__pause-button"
                                    className="Player__button"
                                    onClick={() => !this.state.mediaLoading && this.stop()}
                                >
                                    {
                                        this.state.mediaLoading
                                            ? this.props.renderLoader()
                                            : <IconPause />
                                    }
                                </div>
                            )}
                            <div
                                className="Player__button Player__button--small"
                                onClick={this.next}
                            >
                                <IconNext />
                            </div>
                        </div>
                        {playlistEnabled && (
                            <div className={playlistToggleClasslist.join(' ')}>
                                <IconChevronUp
                                    id="playlist-toggle"
                                    onClick={this.togglePlaylist}
                                />
                            </div>
                        )}
                    </div>
                    <MediaQuery minWidth={1025}>
                        {renderPlaylist()}
                    </MediaQuery>
                    <MediaQuery maxWidth={1024}>
                        <PlayerErrorBoundary onError={this.props.onError}>
                            {renderWaveform()}
                        </PlayerErrorBoundary>
                    </MediaQuery>
                </div>
            </PlayerErrorBoundary>
        );
    }
}
Player.defaultProps = {
    /**
     * Function that on play change passes the new track object out.
     */
    onPlayChange: () => { },
    /**
     * @type {Boolean} shows debug output
     */
    debug: false,
    /**
     * @type {Boolean} If enabled, loading of media asset will be deferred until user initiates playback
     */
    deferLoadMedia: false,
    /**
     * @type {Playlist} If passed a custom Playlist will be used. If omitted the
     * player will construct its own playlist.
     */
    playlist: null,
    /**
     * @type {Boolean} If enabled the player will attempt to play the full track vs a two minute window
     */
    fulltrackEnabled: false,
    /**
     * @type {Boolean} If enabled the player will attempt to use the HlsAdapter if
     * Hls is supported in the browser and the track object contains a stream URL.
     */
    needleDropEnabled: false,
    /**
     * @type {Boolean} If enabled the player will show the playlist toggle.
     */
    playlistEnabled: false,
    /**
     * @type {Object} Should the zoom toggle be displayed.
     */
    zoomEnabled: true,
    /**
     * @type {Object} Props to be passed down to playlist `Tracks`.
     */
    trackProps: {},
    /**
     * @type {Object} render actions for track. (Overrides Track.renderActions.)
     */
    renderTrackActions: null,
    /**
     * @type {Function} Error callback
     */
    onError: () => null,
    /**
     * @type {Function} Callback to be used when the player status changes
     */
    onPlayStateChange: () => null,
    /**
     * @type {Boolean} Automatically scrolls the current playlist track into view
     * on change. Only use if player is fixed to the view.
     */
    autoScrollTrack: true,
    /**
     * @type {Boolean} Set the playlist open state on mount
     */
    initPlaylistOpenState: false,
    /**
     * @type {String} The max height of the playlist drawer. Requires in CSS VH unit,
     * e.g. 100vh, 50vh, etc.
     */
    playlistMaxHeight: '25vh',
    /**
     * @type {Number} The maxmium number of items allowed in the playlist
     */
    playlistMaxLength: 300,
    /**
     * @type {Function} Render prop for custom UI elements on the player bar
     */
    renderPlayerExtra: null,
    /**
     * @type {Function} Render prop for custom loader when player is loading media
     */
    renderLoader: () => <Loader size="large" loading />,
    /**
     * @type {React.Component} Component to render for PlayerArtwork
     */
    PlayerArtwork: PlayerArtwork,
    /**
     * @type {String} The size of the waveform ("full" or "half")
     */
    waveformSize: 'full',
    /**
     * @type {Object} Colors to use on the waveform.
     */
    waveformColorMap: null,
    /**
     * @type {String} Orientation of the Fader volume control
     */
    volumeFaderOrientation: 'vertical',
    /**
     * @type {Function} Method to override the Player.prototype.streamUrl
     */
    streamUrlOverride: null,
    /**
     * @type {Function} Function that returns a boolean to validate whether to use HLS playback. Takes the
     * current track as an argument and defaults to the `is_available_for_streaming` property on the current track.
     */
    validateStreaming: track => track.is_available_for_streaming,
    /**
     * @type {Function} Function to retrieve track playback info when missing.
     * Currently accepts one argument: `trackID`.
     */
    retrieveTrackPlaybackInfo: () => {}
};
