import React from 'react'; // eslint-disable-line no-unused-vars
import { Transition, TransitionGroup } from 'react-transition-group';
import IconX from '~/components/Icons/svg/IconX.js';

const Modal = ({
    open,
    onClose,
    onClick,
    showCloseButton,
    className,
    children,
    ...extra
}) => {
    const componentClass = 'modal__content';
    let classList = componentClass;

    classList = className ? `${classList} ${className}` : classList;

    return (
        <TransitionGroup>
            {open &&
                <Transition timeout={200}>
                    {(state) => {
                        return (
                            <div className={`modal modal--${state}`} onClick={onClose}>
                                <div className={classList} {...extra} onClick={e => {
                                    e.stopPropagation();
                                    onClick(e);
                                }}>
                                    {children}
                                    {showCloseButton && (
                                        <div className="modal__close-button" onClick={onClose}>
                                            <IconX />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }}
                </Transition>
            }
        </TransitionGroup>
    );
};

Modal.defaultProps = {
    onClick: () => { },
    showCloseButton: true,
};

export { Modal };
