const Model = require('./Model');

class Track extends Model {
    constructor (data) {
        const SCHEME = {
            artwork: [
                'images.large.url',
                'stem_pack.image.uri',
                'release.image.uri',
            ],
            mix_name: [
                'mix_name',
                'mix',
            ],
            sample_url: [
                'sample_url',
                'preview.mp3.url',
                // to support sample packs as tracks
                (pack) => {
                    try {
                        return pack.samples.mp3[0].url;
                    }
                    catch (e) {
                        // Do Nothing...
                    }
                },
            ],
            waveform_image: [
                'image.uri',
                'waveform.large.url',
            ],
            label: [
                'label',
                'stem_pack.label',
                'release.label',
            ],
            length_ms: [
                'length_ms',
                'duration.milliseconds',
            ],
            publish_date: [
                'publish_date',
                'date.published',
            ],
            stream_url: [
                'stream_url',
            ],
            sample_start_ms: [
                'sample_start_ms',
                'preview.mp3.offset.start',
            ],
            sample_end_ms: [
                'sample_end_ms',
                'preview.mp3.offset.end',
            ],
            key: (track) => {
                if (typeof track.key === 'string') {
                    return track.key;
                }

                if (typeof track.key === 'object') {
                    return track.key.name;
                }
            },
            genre: [
                'genre',
                (track) => track.genres[0],
            ],
            sub_genre: [
                'sub_genre',
                (track) => track.sub_genres[0],
            ],
        };

        super(data, SCHEME);
    }

    get type () {
        return 'track';
    }
}

module.exports = Track;
