import React from 'react';

const IconArrowLeft = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M66.536 131.035l115.428 115.429c3.15 3.15 8.536.919 8.536-3.535V12.072c0-4.455-5.386-6.687-8.536-3.536L66.536 123.964a4.984 4.984 0 0 0-1.465 3.536c0 1.28.488 2.56 1.465 3.535z"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconArrowLeft;
