import React, { Component } from 'react';

export default class PlayerErrorBoundary extends Component {
    static defaultProps = {
        onError: () => { },
    }

    static getDerivedStateFromError (error) {
        return { error };
    }

    constructor (props) {
        super(props);

        this.state = {
            error: null,
            info: null,
        };
    }

    componentDidCatch (error, info) {
        this.props.onError(error, info);
    }

    render () {
        if (this.state.error) {
            return (
                <div className="PlayerError">
                    <div className="PlayerError__error">
                        {this.state.error.message}
                    </div>
                </div>
            );
        }

        return React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
                props: this.state.error,
            });
        });
    }
}
