/**
 * The AudioAdapter defines an interface for dealing with different
 * audio streams, so that the main Player can utilize the same
 * functions for different audio sources, making it easy to switch between them.
 */
export default class BaseAudioAdapter {
    constructor () {
        this._track = {};
    }

    ready () {
        return new Promise(resolve => resolve(true));
    }

    load () {
        console.warn('BaseAudioAdapter.load has not been defined.');
    }

    play () {
        console.warn('BaseAudioAdapter.play has not been defined.');
        return new Promise(resolve => resolve(true));
    }

    pause () {
        console.warn('BaseAudioAdapter.pause has not been defined.');
    }

    addEventListener () {
        console.warn('BaseAudioAdapter.addEventListener has not been defined.');
    }

    set pitchShift (shift) {
        console.warn('BaseAudioAdapter.pitchShift has not been defined.');
    }

    set currentTime (time) {
        console.warn('BaseAudioAdapter.currentTime has not been defined.');
    }

    get currentTime () {
        console.warn('BaseAudioAdapter.currentTime has not been defined.');
        return 0;
    }

    set volume (volume) {
        console.warn('BaseAudioAdapter.volume has not been defined.');
    }

    get volume () {
        console.warn('BaseAudioAdapter.volume has not been defined.');
        return 1;
    }

    get data () {
        console.warn('BaseAudioAdapter.data has not been defined.');
    }

    set track (track) {
        this._track = track;
    }

    get track () {
        return this._track;
    }

    reset () {
        this.track = {};
        this.sampleRate = 0;
        this.leftChannel = [];
        this.rightChannel = [];
        this.bufferStart = 0;
        this.bufferEnd = 0;
    }

    getBufferFromUrl = url => {
        return fetch(url)
            .then(response => response.arrayBuffer())
            .then(this.decodeAudioDataPromise);
    };

    decodeAudioDataPromise = buffer => {
        return new Promise((resolve, reject) => {
            this.audioContext.decodeAudioData(buffer, resolve, reject);
        });
    };
}
