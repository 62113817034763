import BaseAudioAdapter from './BaseAudioAdapter';

/**
 * Audio adapter for playing multiple synced audio stems
 */
export default class StemsAdapter extends BaseAudioAdapter {
    constructor (mediaElement, audioContext) {
        super();
        this.mediaElement = mediaElement;
        this.audioContext = audioContext;
        this.bufferDownSampleRate = 4410;
        this._pitchShift = 0;
        this.audioTracks = [];

        this.reset();
    }

    reset () {
        this.pause();
        this.currentTime = 0;
        this.leftChannel = [];
        this.rightChannel = [];
        this.audioTracks = [];
        this.mediaElement.src = '';
        super.reset();
    }

    load (track) {
        this.reset();
        this.track = track;

        this.audioTracks = track.parts.map(part => new Audio(part.preview));
        this.pitchShift = this._pitchShift;

        return new Promise(resolve => resolve());
    }

    play (time = null) {
        if (time) {
            this.currentTime = time;
        }

        let playAllParts = this.audioTracks.map(track =>
            new Promise((resolve, reject) => track.play().then(resolve).catch(reject))
        );
        return Promise.all(playAllParts);
    }

    pause () {
        this.audioTracks.forEach(track => {
            try {
                track.pause();
            }
            catch (e) {
                // Do Nothing...
            }
        });
    }

    solo (index) {
        try {
            this.audioTracks
                .filter((t, i) => i !== index)
                .forEach((t, i) => this.mute(i, true));
        }
        catch (e) {
            console.error(e);
        }
    }

    mute (index, value = true) {
        try {
            this.audioTracks[index].muted = value;
        }
        catch (e) {
            console.error(e);
        }
    }

    set pitchShift (shift) {
        this._pitchShift = shift;

        this.audioTracks.forEach(track => {
            track.playbackRate = shift + 1;
        });
    }

    set currentTime (time) {
        this.audioTracks.forEach(track => {
            track.currentTime = time;
        });
    }

    get currentTime () {
        return this.audioTracks[0] && this.audioTracks[0].currentTime;
    }

    set volume (volume) {
        this.audioTracks.forEach(track => {
            track.volume = volume;
        });
    }

    get volume () {
        return this.audioTracks[0] && this.audioTracks[0].volume;
    }

    get duration () {
        return this.audioTracks[0] && this.audioTracks[0].duration;
    }

    get data () {
        let track = this.track;
        return {
            leftChannel: this.leftChannel,
            rightChannel: this.rightChannel,
            trackLength: (track && track.length_ms) || (this.duration * 1000),
            sampleStart: (track && track.sample_start_ms) || 0,
            sampleEnd: (track && track.sample_end_ms) || (this.duration * 1000),
            bufferStart: 0,
            bufferEnd: (track && track.length_ms) || 0,
            sampleRate: this.bufferDownSampleRate,
        };
    }
}
