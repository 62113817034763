import cloudFunction from "./cloudFunction";
import Sentry from '@integrations/Sentry';
import storage from '@utils/localStorage';

/**
 * Here lives the full authorization flow.
 * 
 * 1. Check local storage for existing token.
 * 2. Check token expiration. 
 * 3. Create new token if needed and store.
 */
export const authorize = async () : Promise<beatport.localstorage.token> => {
    let localStorageToken : string;

    try {
      localStorageToken = storage.getItem('beatport.token');
    } catch (e) {
      console.warn(e);

      const token = await fetchApiToken();
      return token;
    }

    if (typeof localStorageToken === 'undefined' || localStorageToken === 'undefined') {
      // console.log('Token found, but undefined. Fetching new token...')
      return refreshToken();
    }

    if (localStorageToken) {
      const token = JSON.parse(localStorageToken);

      if (tokenExpired(token)) {
        // console.log('Token found, but expired. Fetching new token...')
        return refreshToken();
      } else {
        // console.log('Token found.', token)
        return token;
      }
    } else {
      // console.log('No token found. Fetching new token...')
      return refreshToken();
    }
}

/**
 * Fetch API token from Beatport and try to store in localStorage.
 */
export const refreshToken = async () : Promise<beatport.localstorage.token> => {
  const token = await fetchApiToken();

  try {
    storage.setItem('beatport.token', JSON.stringify(token));
  } catch (e) {
    console.warn(e);
  }

  return token;
}

/**
 * Fetch API token from Beatport and add expires as date string.
 */
export const fetchApiToken = async () : Promise<beatport.localstorage.token> => {
  try {
    const response = await fetch(cloudFunction('token'));

    if (response.status === 500) {
      throw new Error('Token endpoint returned 500');
    }

    const responseToken = await response.json();
    
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + responseToken.expires_in);

    const token = {
      ...responseToken,
      expires: expires.toString(),
    }

    return token;
  } catch (e) {
    Sentry.captureException(e);
  }

  return;
}

/**
 * Check a token expiration date (minus 1 hour) and return if still valid.
 */
export const tokenExpired = (token : beatport.localstorage.token) : boolean => {
  if (token?.expires) {
    const now = new Date();
    const expires = new Date(token.expires);
  
    now.setSeconds(now.getSeconds() + 3600);
  
    if (expires < now) {
      return true;
    }
  
    return false;
  }

  return true;
}
