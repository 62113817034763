import React from 'react';

import './Error.scss';

type ErrorProps = {
  title: string;
  error?: Error;
  LogoComponent: (props: any) => JSX.Element;
}

const ErrorComponent: React.FC<ErrorProps> = (props) => {
  return (
    <div className="Error">
      <props.LogoComponent className="Error__logo" />
      {props.title && <h2 style={{ color: '#fff' }}>{props.title}</h2>}
      {props.children}
      {WEBPACK_ENV === 'development' && props.error &&  (
        <div className="Error__stack">
          <pre>
            {props.error.stack}
          </pre>
        </div>
      )}
    </div>
  )
}

export default ErrorComponent;