import React from 'react';

const IconVolumeOn = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <g fill="#000" fillRule="evenodd">
            <path d="M225 203h-13a5 5 0 0 1-5-5V58a5 5 0 0 1 5-5h13a5 5 0 0 1 5 5v140a5 5 0 0 1-5 5M167.5 168.332h-13a5 5 0 0 1-5-5V92.668a5 5 0 0 1 5-5h13a5 5 0 0 1 5 5v70.664a5 5 0 0 1-5 5M107.297 57.95L71.102 81.205a5.004 5.004 0 0 1-2.704.794H31a5 5 0 0 0-5 5v82a5 5 0 0 0 5 5h37.39a5 5 0 0 1 2.703.793l36.204 23.26c3.329 2.136 7.703-.253 7.703-4.208V62.156c0-3.956-4.376-6.345-7.703-4.207" />
        </g>
    </svg>
);

export default IconVolumeOn;
