import React from 'react';

const IconNext = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <g fill="#000" fillRule="evenodd">
            <path d="M188 28h25a5 5 0 0 1 5 5v190a5 5 0 0 1-5 5h-25a5 5 0 0 1-5-5V33a5 5 0 0 1 5-5M45.839 33.406l131.192 90.478c2.882 1.987 2.882 6.245 0 8.232L45.839 222.593c-3.317 2.29-7.84-.086-7.84-4.116V37.522c0-4.029 4.523-6.404 7.84-4.116" />
        </g>
    </svg>
);

export default IconNext;
