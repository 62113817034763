import { useState, useEffect, useContext } from 'react';
import AuthContext from 'context/AuthContext';
import { getChart, getChartTracks, getRelease, getReleaseTracks, getTrack } from '@utils/api';

export type CatalogItem = beatport.api.chart | beatport.api.release;
export type CatalogTypes = 'track' | 'release' | 'chart';

type CatalogReturnValue = {
  item: CatalogItem;
  tracks: beatport.track[];
  loading?: boolean;
  error?: Error;
}


export default function useCatalog(type, id): CatalogReturnValue {
  const auth = useContext(AuthContext);
  const [tracks, setTracks] = useState<beatport.track[]>();
  const [item, setItem] = useState<CatalogItem>()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (tracks?.length && window.BeatportEmbed?.player?.current?.playlist?.length === 0) {
      window.BeatportEmbed?.player?.current?.playlist?.addTrack(tracks[0])
    }
  }, [tracks, window.BeatportEmbed?.player?.current?.playlist]);

  useEffect(() => {
    if (auth.access_token && type && id) {
      switch (type) {
        case 'track':
          getTrack(auth, id)
            .then(track => {
              setTracks([track])
              setLoading(false);
            })
            .catch(e => {
              setError(e);
              setLoading(false);
            });
          break;

        case 'release':
          Promise.all([
            getRelease(auth, id),
            getReleaseTracks(auth, id)
          ])
            .then(([release, tracks]) => {
              setItem(release);
              setTracks(tracks.results);
              setLoading(false);
            })
            .catch(e => {
              setError(e);
              setLoading(false);
            });
          break;

        case 'chart':
        case 'playlist':
          Promise.all([
            getChart(auth, id),
            getChartTracks(auth, id)
          ])
            .then(([chart, tracks]) => {
              setItem(chart);
              setTracks(tracks.results);
              setLoading(false);
            })
            .catch(e => {
              setError(e);
              setLoading(false);
            });
          break;
      }
    } else {
      setLoading(false);
      setTracks([]);
    }
  }, [type, id, auth.access_token]);

  return {
    item,
    tracks,
    loading,
    error,
  }
}