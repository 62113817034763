import React from 'react';

const IconArrowUp = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M124.965 66.536L9.536 181.964c-3.15 3.15-.919 8.536 3.535 8.536h230.857c4.455 0 6.687-5.386 3.536-8.536L132.036 66.536a4.984 4.984 0 0 0-3.536-1.465c-1.28 0-2.56.488-3.535 1.465z"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconArrowUp;
