import React from 'react';

const IconMinus = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M193.057 146H63a5 5 0 0 1-5-5v-25a5 5 0 0 1 5-5h130.057a5 5 0 0 1 5 5v25a5 5 0 0 1-5 5"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconMinus;
