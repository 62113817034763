const React = require('react');

const DefaultAnchor = (props) => {
    console.warn('Warning: No custom Anchor component has been defined. This means you have set useCustomAnchors: true in the config, but have not provided an Anchor component to render.');
    return React.createElement('a', props);
};
let _Beatport = {};

if (typeof window !== 'undefined') {
    window.Beatport = window.Beatport || _Beatport;
    _Beatport = window.Beatport;
}
else if (typeof global !== 'undefined') {
    global.Beatport = global.Beatport || _Beatport;
    _Beatport = global.Beatport;
}

let _config = {};

if (_Beatport.config) {
    _config = _Beatport.config;
}
else {
    _Beatport.config = {
        useCustomAnchors: false,
        Anchor: DefaultAnchor,
        hostname: null,
        urls: {
            artists: '/artist/:slug?/:id?',
            charts: '/chart/:slug?/:id?',
            labels: '/label/:slug?/:id?',
            suppliers: '/supplier/:id?',
            releases: '/release/:slug?/:id?',
            tracks: '/track/:slug?/:id?',
            stems: '/stem/:slug?/:id?',
            genres: '/genre/:slug?/:id?',
            sub_genres: '/sub-genre/:slug?/:id?',
            stem_packs: '/stem-pack/:slug?/:id?',
        },
        url (name, setter = null) {
            if (setter) {
                this.urls[name] = setter;
            }
            return (this.hostname || '') + this.urls[name];
        },
        ...(_Beatport.config) || {},
    };
    _config = _Beatport.config;
}

export default _config;
