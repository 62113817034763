import React from 'react';

const IconChevronUp = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M131.596 71.465l96.062 96.06a5.001 5.001 0 0 1 0 7.072l-9.194 9.192a4.998 4.998 0 0 1-7.07 0l-79.798-79.797a5 5 0 0 0-7.07 0l-79.798 79.797a4.998 4.998 0 0 1-7.07 0l-9.194-9.192a5.001 5.001 0 0 1 0-7.071l96.062-96.061a4.998 4.998 0 0 1 7.07 0"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconChevronUp;
