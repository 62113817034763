const handleApiResponse = (type: string) => (response: Response) => {
  if (response.status === 404) {
    throw new Error(`Sorry, we couldn't find that ${type}.`);
  } else if (response.status === 403) {
    throw new Error(`Sorry, this ${type} is not available.`);
  } else if (response.status > 400) {
    throw new Error('Sorry, something wen\'t wrong.');
  }

  return response;
}

export const getRelease = async (token: beatport.localstorage.token, id: string): Promise<beatport.api.release> => {
  return fetch(`${BEATPORT_API_BASE}/v4/catalog/releases/${id}/`, {
    'headers': {
      'authorization': `Bearer ${token.access_token}`,
      'accept': 'application/json, text/plain, */*',
      'content-type': 'application/json; charset=UTF-8',
    },
    'method': 'GET',
  })
    .then(handleApiResponse('release'))
    .then(r => r.json());
}

export const getReleaseTracks = async (token: beatport.localstorage.token, id: string): Promise<beatport.api.tracks> => {
  return fetch(`${BEATPORT_API_BASE}/v4/catalog/releases/${id}/tracks/?page=1&per_page=100`, {
    'headers': {
      'authorization': `Bearer ${token.access_token}`,
      'accept': 'application/json, text/plain, */*',
      'content-type': 'application/json; charset=UTF-8',
    },
    'method': 'GET',
  })
    .then(handleApiResponse('release'))
    .then(r => r.json());
}

export const getChart = async (token: beatport.localstorage.token, id: string): Promise<beatport.api.chart> => {
  return fetch(`${BEATPORT_API_BASE}/v4/catalog/charts/${id}/`, {
    'headers': {
      'authorization': `Bearer ${token.access_token}`,
      'accept': 'application/json, text/plain, */*',
      'content-type': 'application/json; charset=UTF-8',
    },
    'method': 'GET',
  })
  .then(handleApiResponse('chart'))
    .then(r => r.json());
}

export const getChartTracks = async (token: beatport.localstorage.token, id: string): Promise<beatport.api.tracks> => {
  return fetch(`${BEATPORT_API_BASE}/v4/catalog/charts/${id}/tracks/?page=1&per_page=100`, {
    'headers': {
      'authorization': `Bearer ${token.access_token}`,
      'accept': 'application/json, text/plain, */*',
      'content-type': 'application/json; charset=UTF-8',
    },
    'method': 'GET',
  })
  .then(handleApiResponse('chart'))
    .then(r => r.json());
}


export const getTrack = async (token: beatport.localstorage.token, id: string): Promise<beatport.api.track> => {
  return fetch(`${BEATPORT_API_BASE}/v4/catalog/tracks/${id}/`, {
    'headers': {
      'authorization': `Bearer ${token.access_token}`,
      'accept': 'application/json, text/plain, */*',
      'content-type': 'application/json; charset=UTF-8',
    },
    'method': 'GET',
  })
    .then(handleApiResponse('track'))
    .then(r => r.json());
}
