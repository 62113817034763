import React from 'react';

const IconArrowDown = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M131.035 189.464L246.464 74.036c3.15-3.15.919-8.536-3.535-8.536H12.072c-4.455 0-6.687 5.386-3.536 8.536l115.428 115.428a4.984 4.984 0 0 0 3.536 1.465c1.28 0 2.56-.488 3.535-1.465z"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconArrowDown;
