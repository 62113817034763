/**
 * Generates a function that uses a given function to sort track data
 * provided all data needed for comparison is present, otherwise sorts
 * blank values to the top
 * @param {Function} definedCheck A function to use to check if a track has a defined property
 * @param {Function} sortFn A function to use to sort the tracks
 * @returns {Function} A sort function for a track list
 */
const sortIfDefined = (definedCheck, sortFn) => (a, b) => {
    if (!definedCheck(a) && definedCheck(b)) {
        return -1;
    }

    if (definedCheck(a) && !definedCheck(b)) {
        return 1;
    }

    return definedCheck(a) && definedCheck(b)
        ? sortFn(a, b)
        : 0;
};

/**
 * A collection of functions that return truthy if all data required
 * to access a property is present
 */
const definedChecks = {
    artist: track => track.artists && track.artists.length,
    bpm: track => typeof track.bpm === 'number',
    genre: track => track.genre && track.genre.name,
    key: track => !!track.key,
    label: track => track.label && track.label.name,
    name: track => typeof track.name === 'string',
    purchaseDate: track => typeof track.purchase_date === 'string',
    releaseDate: track => typeof track.publish_date === 'string',
    time: track => typeof track.length_ms === 'number',
};

/**
 * A collection of functions that will correctly sort tracks `a` and `b`
 * assuming all necessary data is present
 */
export const sortFns = {
    artist: (a, b) => a.artists[0].name.localeCompare(b.artists[0].name, 'en'),
    bpm: (a, b) => a.bpm - b.bpm,
    genre: (a, b) => a.genre.name.localeCompare(b.genre.name, 'en'),
    key: (a, b) => a.key.localeCompare(b.key, 'en'),
    label: (a, b) => a.label.name.localeCompare(b.label.name),
    name: (a, b) => a.name.localeCompare(b.name, 'en'),
    purchaseDate: (a, b) => (new Date(a.purchase_date)).getTime() - (new Date(b.purchase_date)).getTime(),
    releaseDate: (a, b) => (new Date(a.publish_date)).getTime() - (new Date(b.publish_date)).getTime(),
    time: (a, b) => a.length_ms - b.length_ms,
};

/**
 * A collection of functions used to sort arrays of track data
 * arrays of track data by a given property. Each function should
 * match the following signature:
 *
 * @param {array} tracks the array of original track data
 * @returns {array} an array of the tracks in the correct order
 */
export const sorters = Object.entries(sortFns).reduce((acc, entry) => {
    const [key, value] = entry;

    acc[key] = tracks => tracks.slice().sort(
        sortIfDefined(definedChecks[key], value)
    );

    return acc;
}, {});
