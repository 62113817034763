import React from 'react';

const IconVolumeOff = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <path
            d="M95 162.846h30.89c3.171 0 6.256.906 8.922 2.62L161 182.29V74.404l-26.183 16.824a16.463 16.463 0 0 1-8.919 2.618H95v69zm72.5 47.846c-3.107 0-6.207-.877-8.918-2.62l-34.601-22.226H88.5c-9.098 0-16.5-7.402-16.5-16.5v-82c0-9.098 7.402-16.5 16.5-16.5h35.489l34.592-22.226a16.49 16.49 0 0 1 16.827-.6A16.492 16.492 0 0 1 184 62.503V194.19a16.492 16.492 0 0 1-8.592 14.48 16.477 16.477 0 0 1-7.908 2.02z"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

export default IconVolumeOff;
