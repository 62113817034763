import React from 'react';
import IconArrowUp from '~/components/Icons/svg/IconArrowUp';
import IconArrowDown from '~/components/Icons/svg/IconArrowDown';

const SortLink = ({ children, id, onSort, sort }) => {
    const handleSortClick = e => {
        e.preventDefault();
        if (sort.direction && sort.id === id) {
            return sort.direction === -1
                ? onSort(id, 0)
                : onSort(id, -1);
        }

        onSort(id, 1);
    };

    const arrow = sort.direction && sort.id === id
        ? sort.direction === 1 ? <IconArrowUp /> : <IconArrowDown />
        : null;

    return (
        <a
            href="#"
            onClick={handleSortClick}
        >
            {children}{arrow}
        </a>
    );
};

export const TrackHeader = ({
    renderActions,
    renderActionsHeader,
    showArtwork,
    showBpm,
    showGenre,
    showLabel,
    showPosition,
    showPurchaseDate,
    showTrackFormat,
    showTitle,
    ...extra
}) => {
    if (!extra.sort) {
        return null;
    }

    const componentClass = 'track';
    let classList = componentClass;

    classList = `${classList} ${componentClass}--header`;
    extra?.sort?.override && (classList = `${classList} ${componentClass}--override`);

    return (
        <div className={classList}>
            {showPosition && <div className="track__position" />}
            {showArtwork && <div className="track__artwork" />}
            {showTitle && (
                <div className="track__col-1 track__col--header">
                    <SortLink id="name" {...extra}>Track Name</SortLink>
                    <SortLink id="artist" {...extra}>Artist/Remixer</SortLink>
                </div>
            )}
            {showGenre && (
                <div className="track__col-2 track__col--header">
                    <SortLink id="genre" {...extra}>Genre</SortLink>
                    <SortLink id="time" {...extra}>Time</SortLink>
                </div>
            )}
            {showBpm && (
                <div className="track__col-3 track__col--header">
                    <SortLink id="key" {...extra}>Key</SortLink>
                    <SortLink id="bpm" {...extra}>BPM</SortLink>
                </div>
            )}
            {showLabel && (
                <div className="track__col-4 track__col--header">
                    <SortLink id="label" {...extra}>Label</SortLink>
                    <SortLink id="releaseDate" {...extra}>Release Date</SortLink>
                </div>
            )}
            {showPurchaseDate && (
                <div className="track__col-5 track__col--header">
                    <SortLink id="purchaseDate" {...extra}>Purchase Date</SortLink>
                    {showTrackFormat && 'Format'}
                </div>
            )}
            {renderActions && (
                <div className="track__col-6 track__col--header">
                    {renderActionsHeader && renderActionsHeader()}
                </div>
            )}
        </div>
    );
};

TrackHeader.defaultProps = {
    renderActions: null,
    renderActionsHeader: null,
    showPosition: true,
    showArtwork: true,
    showTitle: true,
    showGenre: true,
    showBpm: true,
    showLabel: true,
    showPurchaseDate: false,
    showTrackFormat: false,
};
