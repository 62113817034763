import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import WaveformDefaultImage from '~/images/waveform-default.png';
import Color from 'color';

const COLOR_WHITE = Color('rgb(255, 255, 255)');
const COLOR_ACCENT = Color('rgb(57, 193, 222)');
const COLORS_TRANSPARENT = '#0000ffff';

export class Waveform extends Component {
    constructor (props) {
        super(props);
        this.canvas = React.createRef();
        this.state = {
            /**
             * @type {Number} the width of the canvas element
             */
            width: '100%',
            /**
             * @type {Number} the height of the canvas element
             */
            height: '150',
            /**
             * @type {Image} the currently loaded wave form image to draw
             */
            image: null,
        };
    }

    componentDidMount = () => {
        this.setDimensions();
        this.draw();
        this.props.image && this.loadImage(this.props.image);

        window.addEventListener('resize', this.setDimensions);
    };

    componentDidUpdate (prevProps) {
        if (this.props.image !== prevProps.image) {
            this.setState({ image: null },
                () => this.props.image && this.loadImage(this.props.image)
            );
            this.setDimensions();
        }
    }

    componentWillUnmount = () => {
        window.cancelAnimationFrame(this.raf);
        window.removeEventListener('resize', this.setDimensions);
    };

    loadImage = url => {
        const image = new Image();
        image.onload = () => this.setState({ image });
        image.onerror = () => {
            image.src = WaveformDefaultImage;
        };
        image.src = url;
    };

    setDimensions = debounce(() => {
        try {
            const rect = this.canvas.current.parentNode.getBoundingClientRect();
            this.setState({
                width: rect.width,
                height: rect.height,
            });
        }
        catch (e) {
            // do nothing;
        }
    }, 250, {
        trailing: true,
    });

    makeGradient = (context, color, height) => {
        const gradient = context.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, color.darken(0.25).rgb());
        gradient.addColorStop(0.33, color.rgb());
        gradient.addColorStop(0.5, color.lighten(0.25).rgb());
        gradient.addColorStop(0.66, color.rgb());
        gradient.addColorStop(1, color.darken(0.25).rgb());

        return gradient;
    }

    draw = () => {
        const context = this.canvas.current.getContext('2d');
        const { image, width, height } = this.state;
        const {
            waveformSize,
            sampleStart,
            sampleEnd,
            trackLength,
            currentTime,
            zoom,
            onSeek,
        } = this.props;

        const tl = typeof trackLength === 'function' ? trackLength() : trackLength;
        const ct = typeof currentTime === 'function' ? currentTime() : currentTime;
        const ss = typeof sampleStart === 'function' ? sampleStart() : sampleStart;
        const se = typeof sampleEnd === 'function' ? sampleEnd() : sampleEnd;

        context.clearRect(0, 0, width, height);

        if (image) {
            // context.drawImage arguments
            // See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
            let sX = zoom ? (ss / tl) * image.width : 0;
            let sY = 0;
            let sWidth = zoom ? ((se - ss) / tl) * image.width : image.width;
            let sHeight = image.height;
            let dX = 0;
            let dY = 0;
            let dWidth = width;
            let dHeight = waveformSize === 'half' ? height * 2 : height;

            let sampleStartX = zoom ? 0 : (ss / tl) * width;
            let sampleLengthX = zoom ? width : ((se - ss) / tl) * width;

            let currentTimeX = zoom
                ? ((ct - ss) / (se - ss)) * width
                : ((ct - ss) / tl) * width;

            context.globalCompositeOperation = 'destination-atop';
            context.fillStyle = this.makeGradient(context, this.props.colorMap.COLOR_TRACK_AVAILABLE, dHeight);
            context.fillRect(0, 0, width, height);

            // Draw waveform
            context.drawImage(
                image,
                sX,
                sY,
                sWidth,
                sHeight,
                dX,
                dY,
                dWidth,
                dHeight
            );

            // Clear a space for the gradient
            context.clearRect(sampleStartX, 0, sampleLengthX, height);

            // Draw available sample
            context.globalCompositeOperation = 'source-over';
            context.drawImage(
                image,
                (ss / tl) * image.width,
                0,
                ((se - ss) / tl) * image.width,
                image.height,
                sampleStartX,
                0,
                sampleLengthX,
                dHeight
            );

            // Draw played sample
            context.globalCompositeOperation = 'source-atop';
            context.fillStyle = this.makeGradient(context, this.props.colorMap.COLOR_TRACK_PLAYED, dHeight);
            context.fillRect(sampleStartX, 0, currentTimeX, dHeight);

            // Draw remaining track
            context.fillStyle = this.makeGradient(context, this.props.colorMap.COLOR_TRACK_UNPLAYED, dHeight);
            context.fillRect(
                sampleStartX + currentTimeX,
                0,
                sampleLengthX - currentTimeX,
                dHeight
            );

            // Fade out edges
            context.globalCompositeOperation = 'destination-in';
            const gradient = context.createLinearGradient(0, 0, 0, dHeight);
            gradient.addColorStop(0, COLORS_TRANSPARENT);
            gradient.addColorStop(0.1, this.props.colorMap.COLOR_MARKER);
            gradient.addColorStop(0.9, this.props.colorMap.COLOR_MARKER);
            gradient.addColorStop(1, COLORS_TRANSPARENT);
            context.fillStyle = gradient;
            context.fillRect(0, 0, width, dHeight);

            // Draw the available track background if it is passed in
            if (this.props.colorMap.COLOR_TRACK_AVAILABLE_BG) {
                context.globalCompositeOperation = 'destination-over';
                context.fillStyle = this.props.colorMap.COLOR_TRACK_AVAILABLE_BG.rgb();
                context.fillRect(sampleStartX, 0, sampleLengthX, height);
            }

            // Draw current time marker
            context.globalCompositeOperation = 'source-over';
            context.beginPath();
            context.strokeStyle = this.props.colorMap.COLOR_MARKER;
            context.lineWidth = 2;
            context.moveTo(sampleStartX + currentTimeX, 0);
            context.lineTo(sampleStartX + currentTimeX, dHeight);
            context.stroke();

            // Draw sample start marker
            if (ss !== 0) {
                context.globalCompositeOperation = 'source-over';
                context.beginPath();
                context.strokeStyle = this.props.colorMap.COLOR_MARKER;
                context.lineWidth = 2;
                context.moveTo(sampleStartX, 0);
                context.lineTo(sampleStartX, dHeight);
                context.stroke();
            }

            // Draw sample end marker
            if (se !== tl) {
                context.globalCompositeOperation = 'source-over';
                context.beginPath();
                context.strokeStyle = this.props.colorMap.COLOR_MARKER;
                context.lineWidth = 2;
                context.moveTo(sampleStartX + sampleLengthX, 0);
                context.lineTo(sampleStartX + sampleLengthX, dHeight);
                context.stroke();
            }
        }

        // Draw seek marker
        if (onSeek && this.mX) {
            let { left } = this.canvas.current.getBoundingClientRect();
            let dX = this.mX - left;
            const ctx = this.canvas.current.getContext('2d');
            ctx.globalCompositeOperation = 'source-over';
            ctx.beginPath();
            ctx.strokeStyle = this.props.colorMap.COLOR_MARKER;
            ctx.lineWidth = 1;
            ctx.moveTo(dX, 0);
            ctx.lineTo(dX, height);
            ctx.stroke();
        }

        this.raf = window.requestAnimationFrame(this.draw);
    };

    seek = e => {
        let { onSeek } = this.props;

        if (typeof onSeek !== 'function') {
            return;
        }

        let { zoom, trackLength, sampleStart, sampleEnd } = this.props;
        let { left, width } = this.canvas.current.getBoundingClientRect();
        const p = (e.clientX - left) / width;

        const tl = typeof trackLength === 'function' ? trackLength() : trackLength;
        const ss = typeof sampleStart === 'function' ? sampleStart() : sampleStart;
        const se = typeof sampleEnd === 'function' ? sampleEnd() : sampleEnd;

        const t = zoom ? p * (se - ss) + ss : p * tl;

        onSeek(t);
    };

    seekOn = () => {
        window.addEventListener('mousemove', this.getMousePosition);
    };

    seekOff = () => {
        this.mX = null;
        window.removeEventListener('mousemove', this.getMousePosition);
    };

    getMousePosition = e => {
        this.mX = e.clientX;
    };

    render () {
        let className = 'Waveform';
        if (this.props.className) {
            className += ' ' + this.props.className;
        }
        return (
            <div className={className} style={this.props.style}>
                <canvas
                    ref={this.canvas}
                    onMouseOver={this.seekOn}
                    onMouseOut={this.seekOff}
                    width={this.state.width}
                    height={this.state.height}
                    onClick={this.seek}
                    style={{
                        width: '100%',
                    }}
                />
            </div>
        );
    }
}

Waveform.defaultProps = {
    /**
     * @type {String}
     *
     * The image URL to load onto the canas.
     */
    image: null,
    /**
     * @type {String}
     *
     * The size of the waveform ("full" or "half")
     */
    waveformSize: 'full',
    /**
     * @type {Boolean}
     *
     * The waveform will only display the current previewable area of the track.
     */
    zoom: false,
    /**
     * @type {Number|Function}
     *
     * Function that returns the sample start time of the track in milliseconds.
     */
    currentTime: 0,
    /**
     * @type {Number|Function}
     *
     * Function that returns the current playback time in milliseconds.
     */
    sampleStart: 0,
    /**
     * @type {Number|Function}
     *
     * Function that returns the sample end time of the track in milliseconds.
     */
    sampleEnd: 0,
    /**
     * @type {Number|Function}
     *
     * Function that returns the total time of the track in milliseconds.
     */
    trackLength: 0,
    /**
     * @type {Number|Function}
     *
     * The callback for when a user clicks to seek the waveform, called with
     * the current track time in seconds.
     */
    onSeek: null,
    /**
     * @type {Object}
     *
     * Colors to use on the waveform.
     */
    colorMap: {
        COLOR_TRACK_PLAYED: COLOR_ACCENT.lighten(0.4),
        COLOR_TRACK_UNPLAYED: COLOR_ACCENT.darken(0),
        COLOR_TRACK_AVAILABLE: COLOR_ACCENT.darken(0.4),
        COLOR_MARKER: COLOR_WHITE,
    },
    className: null,
    style: {},
};

Waveform.propTypes = {
    zoom: PropTypes.bool,
    sampleStart: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    sampleEnd: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    trackLength: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
};
