import Sentry from '@integrations/Sentry';
import React from 'react';

import ErrorComponent from './ErrorComponent';

type  ErrorBoundaryProps = {
  LogoComponent: (props: any) => JSX.Element;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
    error: null,
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <ErrorComponent LogoComponent={this.props.LogoComponent} title="Sorry, something went wrong." error={error}>
          <p>We&apos;ve been notified and we&apos;re been working on it.</p>
        </ErrorComponent>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
