import React, { Component } from 'react';

const DrawerToggle = (props) => {
    const { className, bordered, open, id, onChange } = props;
    const componentClass = 'drawer-toggle';
    let classList = componentClass;

    classList = className ? `${classList} ${className}` : classList;
    classList = bordered ? `${classList} ${componentClass}--bordered` : classList;

    return (
        <div className={classList}>
            <input className="drawer-toggle__input" type="checkbox" id={id} checked={open} onChange={onChange} />
            <label className="drawer-toggle__label" htmlFor={id}>
                <div className="drawer-toggle__icon">
                    <div className="drawer-toggle__dot" />
                    <div className="drawer-toggle__dot" />
                    <div className="drawer-toggle__dot" />
                    <div className="drawer-toggle__dot" />
                </div>
            </label>
        </div>
    );
};

const appendUnits = (val) => {
    return typeof val === 'number' || val.replace(parseFloat(val), '') === ''
        ? val.toString() + 'px'
        : val;
};

class Drawer extends Component {
    constructor (props) {
        super(props);
        this.state = {
            size: 0,
        };

        window.addEventListener('resize', this.onResize);
    }

    componentDidMount () {
        window.removeEventListener('resize', this.onResize);
        this.calculateSizeFromChildNodes();
    }

    componentDidUpdate () {
        this.calculateSizeFromChildNodes();
    }

    onResize = () => {
        this.calculateSizeFromChildNodes();
        this.render();
    }

    calculateSizeFromChildNodes () {
        if (this.props.size !== 'auto') return;

        const dimension = this.props.direction === 'row' ? 'width' : 'height';
        const childNodes = this.wrapper.childNodes;

        const size = Array.prototype.reduce.call(childNodes, (acc, child) => {
            acc += child.getBoundingClientRect()[dimension]; // eslint-disable-line no-param-reassign
            return acc;
        }, 0) || 0;

        this.state.size !== size && this.setState({ size });
    }

    render () {
        const {
            children,
            direction,
            open,
            overlay,
            position,
        } = this.props;

        const offset = appendUnits(this.props.offset);
        const style = {};
        const size = this.props.size === 'auto'
            ? appendUnits(this.state.size)
            : appendUnits(this.props.size);

        const componentClass = 'drawer-container__drawer';
        let classList = componentClass;

        classList = open ? `${classList} ${componentClass}--open` : classList;
        classList = position && overlay ? `${classList} ${componentClass}--overlay-${position}` : classList;

        let contentClass = 'drawer-container__drawer-content';
        let contentStyle = {};

        contentClass = `${contentClass} ${contentClass}--${position}`;

        const isPercent = size.slice(-1) === '%';
        const styleProp = direction === 'row'
            ? 'width'
            : 'height';

        overlay && (style[styleProp] = size);
        contentStyle[styleProp] = isPercent ? '100%' : size;

        if (open) {
            overlay && (style[position] = '0');
            style.flex = `0 0 ${size}`;
        }
        else {
            overlay && (style[position] = `calc(-${size} + ${offset})`);
            style.flex = `0 0 ${offset}`;
        }

        return (
            <div style={style} className={classList}>
                <div style={contentStyle} className={contentClass} ref={(r) => {
                    this.wrapper = r;
                }}>
                    {children}
                </div>
            </div>
        );
    }
}

const DrawerContainer = (props) => {
    const {
        children,
        className,
        drawer,
        offset,
        open,
        overlay,
        position,
        size,
        style,
    } = props;

    const before = position === 'left' || position === 'top';
    const direction = position === 'left' || position === 'right'
        ? 'row'
        : 'column';

    const componentClass = 'drawer-container';
    let classList = componentClass;

    classList = direction === 'column' ? `${classList} ${componentClass}--column` : classList;
    classList = className ? `${classList} ${className}` : classList;

    const WrappedDrawer = (
        <Drawer
            direction={direction}
            offset={offset}
            open={open}
            overlay={overlay}
            position={position}
            size={size}
        >
            {drawer(props)}
        </Drawer>
    );

    return (
        <div style={style} className={classList}>
            {before && WrappedDrawer}
            {children && (<div className="drawer-container__body">{children}</div>)}
            {!before && WrappedDrawer}
        </div>
    );
};

DrawerContainer.defaultProps = {
    drawer: () => { },
    offset: 0,
    open: false,
    overlay: false,
    position: 'left',
    size: '32rem',
};

export {
    appendUnits,
    DrawerToggle,
    DrawerContainer,
};
