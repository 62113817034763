import React from 'react';
import pathToRegexp from 'path-to-regexp';
import config from '~/components/config';

// map variations of beatport types to a standard convention to use in this library
export const TYPE_MAP = {
    artists: 'artists',
    artist: 'artist',
    chart: 'charts',
    charts: 'charts',
    track: 'tracks',
    tracks: 'tracks',
    release: 'releases',
    releases: 'releases',
    stem: 'stems',
    stems: 'stems',
    pack: 'packs',
    packs: 'packs',
    'stem-pack': 'stem_packs',
    stem_pack: 'stem_packs',
    'stem-packs': 'stem_packs',
};

export const pipe = (...fns) => fns.reduce((f, g) => (...args) => g(f(...args)));

export const uniqueId = () => Math.random().toString(36).substr(2, 9);

export const isValidReactChild = (child) => {
    // basic types
    if (['string', 'number'].includes(typeof child)) {
        return true;
    }

    // react element types
    if (React.isValidElement(child)) {
        return true;
    }

    return false;
};

// TODO: we are hardcoding `500` as width for now so that beatsource imgs render in
// CMS PageModule preview views. We are going to want a more robust solution that works for
// beatport as well, but it will involve a multi-repo rework. Most likely, we will want to
// create a custom intelligent image component similar to what we are using in beatsource repo.
//
export const imageUrl = (src, width = 500, height = null) => {
    const start = 'image_size/';
    const beginningPath = src.substring(0, src.indexOf(start) + start.length);
    const pathWithSize = src.substring(src.indexOf(start) + start.length);
    const imagePath = pathWithSize.substring(pathWithSize.indexOf('/'));

    return width
        ? `${beginningPath}${width}x${height || width}${imagePath}`
        : `${beginningPath.replace('image_size/', 'image')}${imagePath}`;
};

export const urlFor = (type, item = {}) => {
    let t = TYPE_MAP[type] || type;
    let configUrl = config.url(t);
    try {
        let toPath = pathToRegexp.compile(configUrl);
        let route = toPath(item);
        return route;
    }
    catch (e) {
        console.error(`Failed to construct URL for "${type}"`, e);
    }
    return configUrl;
};

/**
 * @description Take string, remove line breaks and padding
 * @param {string} className string to format
 * @returns {string} string with line breaks and padding removed
 */
export const formatClassName = (className) => className.split(' ')
    .map(i => i.replace(/\n/g, ''))
    .filter(i => i)
    .join(' ');

/**
 * @description splitCamelCase takes a string and splits camel case values into proper case titles
 * @param {String} string
 * @returns {String} String with capitalized start and spaces at each capital letter
 */
export const splitCamelCase = (string) => string
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([a-z])/g, ' $1$2')
    .replace(/ +/g, ' ')
    .replace(/^./, (str) => str.toUpperCase())
    .split(' ').filter(i => i).join(' ');

/**
 * @description Take a time in milliseconds and convert to ##h ##m
 * @param {Number} ms time in milliseconds
 * @returns {String} Formatted time, ex. 3h 17m
 */
export const msToDuration = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const hours = Math.floor(minutes / 60);
    const diff = minutes - (hours * 60);
    const hr = hours ? `${hours}h ` : '';
    const min = diff ? `${diff}m` : '';
    return `${hr}${min}`;
};

/**
 * @description Take a time in milliseconds and convert to ##:##
 * @param {Number} ms time in milliseconds
 * @param {Boolean} pad if true, a leading 0 will be added to minutes values less than 10 (e.g. 9 becomes 09)
 * @returns {String} Formatted time
 */
export const msToClock = (ms, pad = false) => {
    if (typeof ms !== 'number' || Number.isNaN(ms)) {
        return (pad ? '0' : '') + '0:00';
    }

    let minutes = Math.floor(ms / 60000);
    let seconds = Math.floor(((ms % 60000) / 1000)).toFixed(0);

    return `${(pad && minutes < 10 ? '0' : '') + minutes}:${(seconds < 10 ? '0' : '') +
      seconds}`;
};

/**
 * @description Take a time in secibds and convert to ##:##
 * @param {Number} s time in seconds
 * @param {Boolean} pad if true, a leading 0 will be added to minutes values less than 10 (e.g. 9 becomes 09)
 * @returns {String} Formatted time
 */
export const secondsToClock = (s, pad = false) => {
    if (typeof s !== 'number' || Number.isNaN(s)) {
        return (pad ? '0' : '') + '0:00';
    }

    let minutes = Math.floor(s / 60);
    let seconds = Math.floor(s - minutes * 60);

    return `${(pad && minutes < 10 ? '0' : '') + minutes}:${(seconds < 10 ? '0' : '') +
      seconds}`;
};

export const downSampleBuffer = (buffer, sampleRate, targetSampleRate) => {
    if (targetSampleRate > sampleRate) {
        throw new Error(`targetSampleRate ${targetSampleRate} cannot exceed sampleRate ${sampleRate}`);
    }

    const interval = sampleRate / targetSampleRate;
    const returnBuffer = [];

    for (let i = 0; i < buffer.length; i += interval) {
        returnBuffer.push(buffer[Math.floor(i)]);
    }

    return returnBuffer;
};

export const debouncePromise = (fn, interval) => {
    let timer = null;

    return (...args) => {
        clearTimeout(timer);
        return new Promise((resolve) => {
            timer = setTimeout(
                () => resolve(fn(...args)),
                interval,
            );
        });
    };
};

export const MONTHS_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

/**
 * Takes a Date object and converts it to Short Month Day, Year.
 *
 * For example, new Date('2019-02-13T11:39:43-07:00') becomes Feb 13, 2019
 *
 * @param {Date} date
 */
export const dateUTCShortMonth = date => {
    const month = MONTHS_SHORT[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month} ${day}, ${year}`;
};

/**
 * Takes a Date object and converts it to Short Month Day, Year.
 *
 * For example, new Date('2019-02-13T11:39:43-07:00') becomes Feb 13, 2019
 *
 * @param {Function} A promise to make cancellable
 *
 * @returns {Object} and object containing a wrapped promise and a cancel function.
 */
export const makeCancelablePromise = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((val) =>
            hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
        );
        promise.catch((error) =>
            hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel () {
            hasCanceled_ = true;
        },
    };
};
