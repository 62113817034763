import React from 'react';

const IconPrev = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <g fill="#000" fillRule="evenodd">
            <path d="M68 228H43a5 5 0 0 1-5-5V33a5 5 0 0 1 5-5h25a5 5 0 0 1 5 5v190a5 5 0 0 1-5 5M210.161 222.594L78.968 132.116c-2.882-1.987-2.882-6.245 0-8.232l131.193-90.477c3.317-2.29 7.84.086 7.84 4.116v180.955c0 4.029-4.523 6.404-7.84 4.116" />
        </g>
    </svg>
);

export default IconPrev;
