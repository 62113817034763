import * as React from "react"

function BeatportLogo(props) {
  return (
    <svg width={250} height={70} viewBox="0 0 250 70" fill="#01FF95" {...props}>  
        <g fill="#000" fillRule="evenodd">
            <path d="M50 44.0591C49.9915 46.9832 49.269 49.8613 47.895 52.4441C46.5208 55.0269 44.5365 57.2366 42.1138 58.8819C39.6912 60.5271 36.9032 61.5584 33.9912 61.8865C31.0792 62.2144 28.131 61.8293 25.4018 60.7645C22.6725 59.6995 20.2444 57.9869 18.3278 55.7748C16.4112 53.5627 15.0639 50.9179 14.4023 48.0691C13.7408 45.2205 13.785 42.2538 14.5311 39.426C15.2773 36.5982 16.7029 33.9944 18.6845 31.8402L6.41938 44.0591L0 37.6536L13.7883 24.0529C14.6971 23.1472 15.4155 22.0696 15.9013 20.8833C16.3871 19.6968 16.6305 18.4255 16.6172 17.144V0H25.6578V17.0847C25.6977 19.5586 25.2295 22.0145 24.2819 24.301C23.3344 26.5876 21.9276 28.6562 20.1484 30.3795L19.7428 30.7841C22.3311 28.4373 25.5455 26.8894 28.997 26.3276C32.4487 25.7659 35.9894 26.2145 39.1908 27.6191C42.3922 29.0236 45.117 31.324 47.0354 34.2417C48.9538 37.1595 49.9835 40.5695 50 44.0591ZM41.8101 44.0591C41.8297 42.1029 41.2663 40.1851 40.1914 38.5491C39.1165 36.913 37.5787 35.6326 35.7731 34.8705C33.9676 34.1083 31.9758 33.8987 30.0505 34.2683C28.1254 34.6379 26.3537 35.57 24.9604 36.9464C23.5673 38.3226 22.6153 40.081 22.2256 41.9983C21.8359 43.9155 22.0259 45.9051 22.7715 47.7143C23.5172 49.5236 24.7849 51.0709 26.4135 52.1598C28.0421 53.2487 29.9585 53.8302 31.9189 53.8303C33.2128 53.842 34.4961 53.598 35.6951 53.1123C36.894 52.6266 37.9847 51.9089 38.9043 51.0006C39.8237 50.0922 40.5539 49.0111 41.0527 47.8197C41.5514 46.6283 41.8089 45.3503 41.8101 44.0591Z"  />
        </g>
        <g fill="#000" fillRule="evenodd">
            <path d="M50.55 44.64C50.5414 47.6027 49.811 50.5187 48.4218 53.1355C47.0325 55.7524 45.0264 57.9912 42.5771 59.6582C40.1278 61.3251 37.3091 62.37 34.3651 62.7024C31.4211 63.0347 28.4404 62.6445 25.6812 61.5656C22.9219 60.4866 20.4671 58.7514 18.5294 56.5102C16.5917 54.2689 15.2296 51.5892 14.5607 48.7029C13.8919 45.8167 13.9366 42.8109 14.6909 39.9458C15.4453 37.0807 16.8866 34.4426 18.89 32.26L6.48999 44.64L0 38.15L13.94 24.37C14.8588 23.4524 15.5851 22.3606 16.0762 21.1586C16.5674 19.9565 16.8134 18.6684 16.8 17.37V0H25.94V17.31C25.9804 19.8165 25.507 22.3048 24.549 24.6214C23.5911 26.9381 22.1688 29.034 20.37 30.78L19.96 31.19C22.5767 28.8122 25.8265 27.2439 29.316 26.6747C32.8056 26.1056 36.3853 26.5601 39.6219 27.9832C42.8585 29.4063 45.6133 31.737 47.5528 34.6932C49.4923 37.6494 50.5333 41.1044 50.55 44.64ZM42.27 44.64C42.2898 42.658 41.7202 40.7149 40.6335 39.0573C39.5468 37.3997 37.9921 36.1024 36.1666 35.3302C34.3412 34.558 32.3275 34.3456 30.3811 34.7201C28.4348 35.0946 26.6436 36.039 25.235 37.4335C23.8265 38.8279 22.8641 40.6095 22.4701 42.552C22.0761 44.4945 22.2682 46.5103 23.022 48.3434C23.7759 50.1765 25.0575 51.7442 26.704 52.8475C28.3506 53.9508 30.288 54.5399 32.27 54.54C33.5781 54.5519 34.8756 54.3047 36.0877 53.8126C37.2998 53.3205 38.4025 52.5933 39.3322 51.673C40.2618 50.7526 41 49.6573 41.5043 48.4502C42.0085 47.2431 42.2688 45.9482 42.27 44.64Z"  />
            <path d="M80.5492 26.86C84.3892 26.86 87.3592 27.66 89.4792 29.26C90.5165 30.0318 91.3487 31.0462 91.9031 32.2142C92.4575 33.3822 92.7171 34.6684 92.6592 35.96C92.7355 37.8288 92.1395 39.663 90.9792 41.13C89.7275 42.5588 88.0284 43.5212 86.1592 43.86C91.3926 44.76 94.0092 47.51 94.0092 52.11C94.0657 53.4862 93.7878 54.8557 93.1991 56.1009C92.6104 57.3462 91.7286 58.4302 90.6292 59.26C88.3825 61 85.1725 61.8667 80.9992 61.86H67.6992V26.86H80.5492ZM73.4492 41.71H80.6992C82.3369 41.8107 83.958 41.3329 85.2792 40.36C85.8162 39.906 86.241 39.334 86.5203 38.6887C86.7996 38.0434 86.9258 37.3422 86.8892 36.64C86.921 35.9423 86.7904 35.2467 86.5075 34.6081C86.2246 33.9695 85.7973 33.4053 85.2592 32.96C83.931 32.0039 82.3125 31.5374 80.6792 31.64H73.4292L73.4492 41.71ZM73.4492 57.01H81.0492C82.8943 57.126 84.7279 56.6457 86.2792 55.64C86.8745 55.2002 87.3527 54.621 87.6717 53.9532C87.9908 53.2854 88.141 52.5494 88.1092 51.81C88.1456 51.0507 87.9961 50.2941 87.6737 49.6057C87.3513 48.9174 86.8658 48.3181 86.2592 47.86C84.72 46.8413 82.8917 46.35 81.0492 46.46H73.4492V57.01Z"  />
            <path d="M96.691 42.1799C97.6857 40.2559 99.2132 38.659 101.091 37.5799C103.066 36.4758 105.299 35.9167 107.561 35.9599C109.828 35.9122 112.072 36.4276 114.091 37.4599C115.943 38.432 117.485 39.9045 118.541 41.7099C119.657 43.6753 120.237 45.8997 120.221 48.1599C120.217 48.8292 120.167 49.4975 120.071 50.1599H100.761V50.4599C100.786 52.4245 101.534 54.311 102.861 55.7599C103.512 56.4195 104.292 56.9377 105.152 57.2818C106.013 57.626 106.935 57.7887 107.861 57.7599C109.348 57.812 110.815 57.4034 112.061 56.5899C113.203 55.8021 114.006 54.6132 114.311 53.2599H119.721C119.296 55.7874 117.949 58.0676 115.941 59.6599C113.756 61.3738 111.036 62.2594 108.261 62.1599C105.828 62.2222 103.419 61.6648 101.261 60.5399C99.3117 59.4974 97.712 57.9047 96.6611 55.9599C95.538 53.8546 94.9766 51.4954 95.031 49.1099C95.0052 46.6984 95.5754 44.3179 96.691 42.1799ZM114.691 46.2599C114.551 44.5661 113.754 42.9941 112.471 41.8799C111.135 40.803 109.455 40.2454 107.741 40.3099C106.071 40.2765 104.447 40.8524 103.171 41.9299C102.533 42.4694 102.007 43.1296 101.624 43.8726C101.242 44.6156 101.009 45.4269 100.941 46.2599H114.691Z"  />
            <path d="M146.381 57.16V61.86H143.531C142.241 61.9809 140.954 61.6054 139.931 60.81C139.551 60.4124 139.258 59.9408 139.068 59.4246C138.879 58.9084 138.798 58.3588 138.831 57.81C137.936 59.2239 136.683 60.3758 135.199 61.1486C133.715 61.9214 132.053 62.2876 130.381 62.21C127.919 62.3366 125.485 61.6323 123.471 60.21C122.619 59.563 121.938 58.7169 121.488 57.7459C121.038 56.775 120.833 55.7086 120.891 54.64C120.83 53.4769 121.053 52.3164 121.54 51.2587C122.028 50.2011 122.766 49.2783 123.691 48.57C125.564 47.15 128.284 46.44 131.851 46.44H138.301V44.89C138.33 44.2489 138.21 43.6098 137.949 43.0232C137.689 42.4366 137.296 41.9186 136.801 41.51C135.6 40.6363 134.134 40.2052 132.651 40.29C131.287 40.2292 129.936 40.5774 128.771 41.29C128.293 41.5924 127.883 41.9914 127.567 42.4616C127.252 42.9317 127.039 43.4625 126.941 44.02H121.641C121.724 42.8296 122.073 41.673 122.661 40.6348C123.25 39.5967 124.063 38.7032 125.041 38.02C127.377 36.5129 130.124 35.7718 132.901 35.9C136.328 35.9 138.994 36.7 140.901 38.3C141.854 39.1672 142.601 40.2374 143.086 41.4316C143.57 42.6259 143.78 43.9137 143.701 45.2V55.3C143.701 56.5 144.261 57.1 145.401 57.1L146.381 57.16ZM138.281 50.46H131.581C130.222 50.3827 128.872 50.7324 127.721 51.46C127.274 51.7878 126.917 52.2221 126.681 52.7234C126.445 53.2247 126.339 53.777 126.371 54.33C126.357 54.8419 126.466 55.3498 126.688 55.8109C126.911 56.272 127.241 56.6729 127.651 56.98C128.664 57.698 129.891 58.0506 131.131 57.98C133.041 58.0942 134.918 57.447 136.351 56.18C136.983 55.5536 137.48 54.8047 137.812 53.9793C138.144 53.1539 138.303 52.2694 138.281 51.38V50.46Z"  />
            <path d="M148.501 41.01H144.051V36.26H148.501V29.11H153.951V36.26H160.111V41.01H153.951V54.36C153.872 55.1205 154.087 55.8824 154.551 56.49C155.126 56.9592 155.863 57.182 156.601 57.11H160.711V61.86H155.501C153.071 61.86 151.291 61.3 150.181 60.16C149.071 59.02 148.501 57.28 148.501 54.91V41.01Z"/>
            <path d="M182.09 37.5299C183.934 38.5981 185.428 40.1788 186.39 42.0799C187.465 44.2536 187.997 46.6556 187.94 49.0799C187.987 51.4648 187.455 53.8256 186.39 55.9599C185.421 57.8694 183.93 59.4645 182.09 60.5599C180.189 61.6568 178.025 62.2168 175.83 62.1799C174.104 62.2294 172.39 61.8696 170.83 61.1299C169.49 60.4784 168.338 59.4978 167.48 58.2799V71.8899H162.08V36.2599H166.78L167.43 40.0599C168.382 38.7473 169.64 37.6875 171.096 36.9728C172.551 36.2582 174.159 35.9105 175.78 35.9599C177.986 35.9117 180.164 36.4537 182.09 37.5299ZM180.36 55.2099C181.787 53.4858 182.527 51.296 182.44 49.0599C182.536 46.8225 181.794 44.6297 180.36 42.9099C179.664 42.173 178.824 41.5859 177.893 41.1847C176.962 40.7835 175.959 40.5766 174.945 40.5766C173.931 40.5766 172.928 40.7835 171.997 41.1847C171.066 41.5859 170.226 42.173 169.53 42.9099C168.132 44.618 167.419 46.7852 167.53 48.9899C167.417 51.2468 168.128 53.4679 169.53 55.2399C170.215 55.9955 171.051 56.5994 171.984 57.0126C172.916 57.4258 173.925 57.6393 174.945 57.6393C175.965 57.6393 176.974 57.4258 177.906 57.0126C178.839 56.5994 179.675 55.9955 180.36 55.2399V55.2099Z"  />
            <path d="M195.81 60.54C193.862 59.4869 192.263 57.8883 191.21 55.94C190.144 53.812 189.59 51.4649 189.59 49.085C189.59 46.7051 190.144 44.358 191.21 42.23C192.268 40.2802 193.865 38.6763 195.81 37.61C197.924 36.5287 200.265 35.9648 202.64 35.9648C205.015 35.9648 207.356 36.5287 209.47 37.61C211.415 38.6763 213.012 40.2802 214.07 42.23C215.136 44.358 215.69 46.7051 215.69 49.085C215.69 51.4649 215.136 53.812 214.07 55.94C213.017 57.8883 211.418 59.4869 209.47 60.54C207.351 61.605 205.012 62.1597 202.64 62.1597C200.268 62.1597 197.929 61.605 195.81 60.54ZM208.12 55.19C209.463 53.4286 210.19 51.275 210.19 49.06C210.19 46.845 209.463 44.6914 208.12 42.93C207.441 42.152 206.596 41.5356 205.648 41.1256C204.7 40.7156 203.672 40.5223 202.64 40.56C201.612 40.5204 200.588 40.7129 199.645 41.1232C198.701 41.5335 197.862 42.151 197.19 42.93C195.86 44.6967 195.14 46.8483 195.14 49.06C195.14 51.2717 195.86 53.4233 197.19 55.19C197.862 55.969 198.701 56.5865 199.645 56.9968C200.588 57.4071 201.612 57.5996 202.64 57.56C203.672 57.5977 204.7 57.4044 205.648 56.9944C206.596 56.5844 207.441 55.968 208.12 55.19Z"  />
            <path d="M231.699 36.26V41.26H229.299C228.371 41.179 227.438 41.3357 226.588 41.7154C225.738 42.095 224.998 42.6852 224.439 43.43C223.384 45.0761 222.861 47.0065 222.939 48.96V61.81H217.539V36.21H222.339L222.939 40.06C223.643 38.8976 224.622 37.9258 225.789 37.23C227.245 36.4912 228.869 36.1464 230.499 36.23L231.699 36.26Z"/>
            <path d="M233.439 29.11H238.889V36.26H245.099V41.01H238.889V54.36C238.81 55.1205 239.025 55.8824 239.489 56.49C240.067 56.9607 240.807 57.1836 241.549 57.11H245.649V61.86H240.389C237.959 61.86 236.189 61.3 235.089 60.16C233.989 59.02 233.439 57.28 233.439 54.91V29.11Z"/>
        </g>
    </svg>
  )
}

export default BeatportLogo
