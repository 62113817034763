import { BroadcastChannel } from 'broadcast-channel';

export const uniqueId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';

  for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
}

export const INSTANCE_ID = uniqueId();

export const initBroadcastChannel = () => {
  const channel = new BroadcastChannel('beatport.player');

  channel.addEventListener('message', (event) => { 
    const data = JSON.parse(event);
    
    if (data.instance_id === INSTANCE_ID) {
      return;
    }

    switch (data.code) {
      case 'playstatechange':
        if (data.status === 'PLAYING') {
          try {
            window.BeatportEmbed.player.current.stop();
          } catch (e) {
            console.warn(e);
          }
        }
    }
  })

  return channel;
}