import { useState, useEffect } from 'react';
import { authorize, tokenExpired } from "utils/auth";
import { defaultAuthContext } from "context/AuthContext";
import useInterval from "./useInterval";

type UseAuthReturnValue = {
  auth?: beatport.localstorage.token;
  loading: boolean;
  error?: Error;
}

export default function useAuth() : UseAuthReturnValue {
  const [auth, setAuth] = useState<beatport.localstorage.token>(defaultAuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [needsAuth, setNeedsAuth] = useState(true);

  useInterval(() => {
    if (tokenExpired(auth)) {
      setNeedsAuth(true);
    }
  }, 60000)

  useEffect(() => {
    if (needsAuth) {
      authorize()
        .then(token => {
          setLoading(false);
  
          if (token) {
            setNeedsAuth(false)
            setAuth(token);
          }
        })
        .catch(e => {
          setError(e);
          setLoading(false);
        });
    }
  }, [needsAuth]);

  return {
    auth,
    loading,
    error,
  }
}