import { CookieStorage, isSupported } from 'local-storage-fallback';

let storage: Storage | StorageFallback;

try {
  storage = window.localStorage; // this will throw under certain security settings

  if (!isSupported('localStorage')) { 
    throw new Error('localStorage not supported.')
  }
} catch (e) {
  storage = new CookieStorage({
    prefix: '',
    secure: true,
    sameSite: 'none',
  });
}

export default storage
