import React from 'react';

const IconPause = ({ svgRef, ...props }) => (
    <svg viewBox="0 0 256 256" width="1em" height="1em" ref={svgRef} {...props}>
        <g fill="#000" fillRule="evenodd">
            <path d="M92 228H67a5 5 0 0 1-5-5V33a5 5 0 0 1 5-5h25a5 5 0 0 1 5 5v190a5 5 0 0 1-5 5M189 228h-25a5 5 0 0 1-5-5V33a5 5 0 0 1 5-5h25a5 5 0 0 1 5 5v190a5 5 0 0 1-5 5" />
        </g>
    </svg>
);

export default IconPause;
