import React from 'react';

const LoaderWrapper = (props) => {
    return (
        <div className={`loader-wrapper ${props.loading ? 'loader-wrapper--loading' : ''}`}>
            {props.loading ? (
                <div className="container container--full-size loader-wrapper__loader">
                    <div className="full-center">
                        <Loader size="large" loading />
                    </div>
                </div>
            ) : null}
            <div className={`loader-wrapper__content ${props.loading ? 'loader-wrapper__content--loading' : ''}`}>
                {props.children}
            </div>
        </div>
    );
};

class LoaderAPI extends React.Component {
    state = {
        loading: this.props.loading,
    };

    loaderComplete () {
        this.setState({
            loading: false,
        });
    }

    loaderStart () {
        this.setState({
            loading: true,
        });
    }

    render () {
        return (
            <LoaderWrapper loading={this.state.loading}>
                {React.Children.map(this.props.children, (child) =>
                    React.cloneElement(child, {
                        ...child.props,
                        loaderComplete: this.loaderComplete.bind(this),
                        loaderStart: this.loaderStart.bind(this),
                        loaderLoading: this.state.loading,
                    })
                )}
            </LoaderWrapper>
        );
    }
}
LoaderAPI.defaultProps = {
    loading: true,
};

function withLoader (WrappedComponent) {
    return ({ wrappedRef, loading, ...extra }) => {
        return (
            <LoaderAPI loading={loading} ref={wrappedRef}>
                <WrappedComponent {...extra} />
            </LoaderAPI>
        );
    };
}
withLoader.defaultProps = {
    wrappedRef: () => { },
    loading: true,
};

const Loader = ({ className, size, loading }) => {
    let classList = size === 'large' ? 'loader loader--large' : 'loader';
    classList = loading ? classList += ' loader--loading' : classList;
    classList = className ? `${classList} ${className}` : classList;

    return (
        <div className={classList}>
            <div className="loader__spinner">
                <div className="loader__spinner-icon" />
            </div>
        </div>
    );
};
Loader.defaultProps = {
    size: 'small',
};

const ProgressLoader = (props) => {
    let {
        progress,
        className,
        ...extra
    } = props;

    return (
        <div className={`progress-loader ${className}`} {...extra}>
            <div key={1} className="progress-loader__total" />
            <div key={2} className="progress-loader__completed" style={{ width: `${progress}%` }} />
        </div>
    );
};
ProgressLoader.defaultProps = {
    progress: 0,
    className: '',
};

export { Loader, LoaderWrapper, ProgressLoader, withLoader };
